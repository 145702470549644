<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
    <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
        <img :src="loadingGif" alt="Loading..." class="h-28" />
        <p class="text-white ml-3">Loading...</p>
    </div>
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li>
          <router-link to="/JobDescriptionLibrary" class="text-company-color-1 hover:text-company-color-2">Job Description Library</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">Job Description Uploader</li>
      </ol>
    </nav>
    <div class="flex flex-col items-center justify-center p-4">
      <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full mb-4">
        <h2 class="text-2xl lg:text-3xl font-semibold mb-6">Job Description Information</h2>
        <form>
          <div class="mb-4">
            <label class="block text-gray-700 dark:text-gray-300 mb-2 font-semibold">Title:</label>
            <input v-model="title" type="text" class="w-full p-2 rounded-md bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" readonly />
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 dark:text-gray-300 mb-2 font-semibold">Document Name:</label>
            <input v-model="docName" type="text" class="w-full p-2 rounded-md bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" readonly />
          </div>
          <div class="flex justify-start">
            <button type="button" @click="openEditDialog" class="bg-green-500 hover:bg-green-400 text-white py-2 px-4 rounded-md mr-2">Edit</button>
            <button type="button" @click="openDeleteDialog" class="bg-red-500 hover:bg-red-400 text-white py-2 px-4 rounded-md">Delete</button>
          </div>
        </form>
      </div>

      <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full">
        <p class="text-2xl lg:text-3xl font-semibold mb-10">
          Please upload the newest version of <span class="text-company-color-1">{{ docName }}</span>
        </p>
        <form @submit.prevent="onSubmit" enctype="multipart/form-data">
          <p class="text-sm text-gray-600 dark:text-gray-300 mb-4">
            <span class="text-red-600">Note:</span> The uploaded file should differ from the original file in formatting and styling only, not in the variable aspect for form generation. Contact the <span class="text-blue-400 font-semibold">IT department</span> for changes regarding variables.
          </p>
          <div class="mb-4">
            <label class="block text-gray-700 dark:text-gray-300 mb-2">Upload File</label>
            <div @click="triggerFileInput" @dragover.prevent @dragenter.prevent @drop="onDrop" class="border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700">
              <input type="file" ref="file" @change="onSelect" class="hidden" />
              <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                <i class="fa-solid fa-download"></i>
              </div>
              <p v-if="selectedFile" class="mt-2 text-gray-600 dark:text-gray-300">{{ selectedFile.name }}</p>
              <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop files here to upload</p>
            </div>
          </div>
          <div v-if="message" class="my-4">
            <h5 class="text-gray-700 dark:text-gray-300">{{ message }}</h5>
          </div>
          <div>
            <button type="submit" class="w-28 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-300 focus:outline-none focus:shadow-outline">
              Submit
            </button>
          </div>
        </form>
      </div>

      <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full mt-4">
        <p class="text-xl lg:text-2xl font-semibold mb-10">
          Click here if you wish to download the <span class="text-company-color-1">current version</span> of the template
        </p>
        <button @click="downloadCurrentVersion" class="w-28 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-300 focus:outline-none focus:shadow-outline">
          Download
        </button>
      </div>
    </div>

    <div v-if="showEditDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Edit Job Description Information</h3>
                <div class="mt-2">
                  <div class="mb-4">
                    <label class="block text-gray-700 dark:text-gray-300 mb-2">Title:</label>
                    <input v-model="editTitle" type="text" class="w-full p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" required />
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 dark:text-gray-300 mb-2">Document Name:</label>
                    <input v-model="editDocName" type="text" class="w-full p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" required />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="confirmEdit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
              Save changes
            </button>
            <button @click="closeEditDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showDeleteDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Confirm Delete</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500 dark:text-gray-400">Are you sure you want to delete this job description? This action cannot be undone.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="confirmDelete" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Delete
            </button>
            <button @click="closeDeleteDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, setDoc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { storage, db } from "@/firebase/init";
import loadingGif from "@/assets/images/loading.gif";
import { useToast } from "vue-toastification";

export default {
  name: "FileUploader",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: '',
      docName: '',
      editTitle: '',
      editDocName: '',
      selectedFile: null,
      message: "",
      loading: false,
      showEditDialog: false,
      showDeleteDialog: false,
      loadingGif
    };
  },
  methods: {
    async fetchJobDescriptionDetails() {
      try {
        const docRef = doc(db, 'Job_Descriptions_Titles', this.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.title = docSnap.data().title;
          this.docName = docSnap.data().docName;
          this.editTitle = this.title;
          this.editDocName = this.docName;
        } else {
          this.message = "Job description not found!";
        }
      } catch (error) {
        console.error("Error fetching job description:", error);
        this.message = "Failed to fetch job description.";
      }
    },
    openEditDialog() {
      this.showEditDialog = true;
    },
    closeEditDialog() {
      this.showEditDialog = false;
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    async confirmEdit() {
      this.closeEditDialog();
      this.loading = true;
      const toast = useToast();
      try {
        const docRef = doc(db, 'Job_Descriptions_Titles', this.id);
        await updateDoc(docRef, {
          title: this.editTitle,
          docName: this.editDocName,
        });
        this.title = this.editTitle;
        this.docName = this.editDocName;
        toast.success("Job description updated successfully!");
      } catch (error) {
        console.error("Error updating job description:", error);
        toast.error("Failed to update job description.");
      } finally {
        this.loading = false;
      }
    },
    async confirmDelete() {
      this.closeDeleteDialog();
      this.loading = true;
      const toast = useToast();
      try {
        const docRef = doc(db, 'Job_Descriptions_Titles', this.id);
        await deleteDoc(docRef);

        const fileRef = storageRef(storage, `Job Descriptions/${this.docName}`);
        await deleteObject(fileRef);

        toast.success("Job description deleted successfully!");
        this.$router.push('/JobDescriptionLibrary');
      } catch (error) {
        console.error("Error deleting job description:", error);
        toast.error("Failed to delete job description.");
      } finally {
        this.loading = false;
      }
    },
    triggerFileInput() {
      this.$refs.file.click();
    },
    onSelect() {
      const allowedTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];
      const file = this.$refs.file.files[0];
      this.selectedFile = file;
      if (!allowedTypes.includes(file.type)) {
        this.message = "Only .doc and .docx files are allowed!";
        this.selectedFile = null;
      } else if (file.size > 1000000) {
        this.message = "Too large, max size allowed is 1000KB";
        this.selectedFile = null;
      } else {
        this.message = "";
      }
    },
    onDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.selectedFile = file;
      this.validateFile(file);
    },
    validateFile(file) {
      const allowedTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];
      if (!allowedTypes.includes(file.type)) {
        this.message = "Only .doc and .docx files are allowed!";
        this.selectedFile = null;
      } else if (file.size > 1000000) {
        this.message = "Too large, max size allowed is 1000KB";
        this.selectedFile = null;
      } else {
        this.message = "";
      }
    },
    async onSubmit() {
      if (!this.selectedFile) {
        this.message = "Please select a valid file";
        return;
      }

      const toast = useToast();
      const fileName = `Job Descriptions/${this.docName}`;
      const fileRef = storageRef(storage, fileName);

      this.loading = true;

      try {
        await deleteObject(fileRef).catch(error => {
          if (error.code !== 'storage/object-not-found') {
            throw error;
          }
        });

        await uploadBytes(fileRef, this.selectedFile);
        const fileURL = await getDownloadURL(fileRef);

        await setDoc(doc(db, 'Job_Descriptions', this.docName), { url: fileURL });

        toast.success("File uploaded successfully!");
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Upload failed!");
      } finally {
        this.loading = false;
      }
    },
    async downloadCurrentVersion() {
      const toast = useToast();
      try {
        const docRef = doc(db, 'Job_Descriptions', this.docName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          toast.info('Download started. Please wait...');
          const fileURL = docSnap.data().url;

          const link = document.createElement('a');
          link.href = fileURL;
          link.download = `${this.docName}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast.success("Downloaded Successfully!");
        } else {
          toast.error("No current version found!");
        }
      } catch (error) {
        console.error("Error downloading file:", error);
        toast.error("Download failed!");
      }
    },
    resetForm() {
      this.fetchJobDescriptionDetails();
      this.selectedFile = null;
      this.message = '';
    }
  },
  async mounted() {
    await this.fetchJobDescriptionDetails();
  }
};
</script>

<style scoped>
</style>
