<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 p-4 h-full">
    <nav class="px-4 pt-2 mb-1 mx-4 text-xs lg:text-md h-6">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
              <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
              <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Introduction</li>
      </ol>
    </nav>
    <div class="flex flex-wrap">
      <div class="mx-auto max-w-2xl h-full flex flex-col justify-center">
        <div class="text-2xl md:text-4xl text-company-color-1 text-center mb-8 md:mb-24 font-semibold mt-4">
          Welcome to {{ companyName }} Intranet!
        </div>
        <div class="text-justify h-full space-y-6 md:space-y-8 p-2 text-sm">
          <p>Our intranet is a dedicated online platform designed exclusively for our company's internal use.  
            It serves as a central hub for all employees, providing easy access to essential information and resources. 
            From mission and vision statements to our company's core values, you'll find comprehensive details about our organization.
          </p>

          <p>The intranet houses important documents such as the company structure, organizational chart, and definitions of key processes, ensuring clarity and consistency across the board.
            It serves as a secure and reliable source of information, accessible only to authorized personnel within our company.
          </p>

          <p>One of the primary purposes of our intranet is to enhance understanding and efficiency within our organization.  
            Whether you're a new employee in need of training materials or an experienced team member seeking process compliance, the intranet will be your go-to resource. 
            It provides a central location for important company-wide announcements, updates, and policies.
          </p>

          <p>Additionally, the intranet provides a dedicated section for our employee handbook and HR-related information.  
            Here, you'll find a range of templates and guidelines that will aid both employees and hiring managers in their day-to-day activities. 
            Our HR department leverages this platform to communicate important HR updates and ensure that employees have access to the most up-to-date information.
          </p>

          <p>We have carefully curated the content on our intranet to focus on processes that are critical to our company's operations and have a significant impact on our overall success. 
            By centralizing this information and making it easily accessible, we aim to foster a more informed and collaborative work environment.
          </p>

          <p>We invite you to explore the {{ companyName }} intranet and take advantage of the wealth of information it offers. 
            Together, let's harness the power of knowledge to drive our company forward.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase/init';

export default {
  name: "Introduction",
  data() {
    return {
      companyName: '',
    };
  },
  async created() {
    await this.fetchCompanyName();
  },
  methods: {
    async fetchCompanyName() {
      try {
        const docRef = doc(db, 'configuration', 'companyInfo');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.companyName = docSnap.data().companyName || 'Our Company';
        } else {
          console.error('No companyInfo document found!');
          this.companyName = 'Our Company';
        }
      } catch (error) {
        console.error('Error fetching company name:', error);
        this.companyName = 'Our Company';
      }
    }
  }
};
</script>

<style scoped>
</style>
