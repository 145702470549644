<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li><span class="mx-2">></span></li>
        <li class="text-gray-500 dark:text-gray-400">Manage PDFs</li>
      </ol>
    </nav>

    <div class="w-full 2xl:w-3/4 mx-auto mt-4 p-4">
      <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <h2 class="font-bold text-2xl md:text-3xl mb-4">Add PDF From Google Drive</h2>
        <form @submit.prevent="addPdf" class="space-y-4">
          <div class="grid md:grid-cols-2 gap-4">
            <div>
              <label for="documentName" class="block">Document Name:</label>
              <input
                type="text"
                v-model="formData.documentName"
                id="documentName"
                required
                placeholder="Enter document name"
                class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
              />
            </div>
            <div>
              <label for="driveLink" class="block">Google Drive Link (Full Link):</label>
              <input
                type="url"
                v-model="formData.driveLink"
                id="driveLink"
                required
                placeholder="Enter Google Drive sharing link"
                class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
              />
            </div>
          </div>
          <button type="submit" class="bg-blue-500 hover:bg-blue-300 text-white py-2 px-4 rounded-md">Add PDF</button>
        </form>
      </div>
    </div>

    <div class="w-full 2xl:w-3/4 mx-auto mt-8 p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="font-bold text-2xl md:text-3xl">PDFs</h2>
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search for PDFs..."
          class="p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
        />
      </div>
      <div class="overflow-x-auto">
        <div class="min-w-full inline-block align-middle overflow-hidden bg-white dark:bg-gray-800 mb-4 shadow sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
            <thead class="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th class="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Document Name</th>
                <th class="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Google Drive Sharing ID</th>
                <th class="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-600">
              <tr v-for="pdf in filteredPdfs" :key="pdf.id">
                <td class="px-6 py-4 whitespace-nowrap">{{ pdf.documentName }}</td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <a :href="'https://drive.google.com/file/d/' + pdf.fileId + '/view'" target="_blank" class="text-blue-500 hover:text-blue-300 hover:underline">{{ pdf.fileId }}</a>
                </td>
                <td class="px-6 py-4 whitespace-nowrap flex space-x-2">
                  <button @click="openEditDialog(pdf)" class="bg-green-500 hover:bg-green-300 text-white px-4 py-2 rounded">Edit</button>
                  <button @click="openDeleteDialog(pdf.id)" class="bg-red-500 hover:bg-red-400 text-white px-4 py-2 rounded">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="p-4 flex justify-between items-center">
            <div class="text-sm">
              Rows per page:
              <select v-model="rowsPerPage" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2">
                <option v-for="option in [5, 10, 15, 20]" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>
            <div class="text-sm">
              <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded mr-2">Prev</button>
              {{ paginationInfo }}
              <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded ml-2">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showEditDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Edit PDF</h3>
                <div class="mt-2">
                    <label for="editDocumentName" class="block">Document Name:</label>
                    <input
                        type="text"
                        v-model="editPdf.documentName"
                        id="editDocumentName"
                        required
                        placeholder="Enter document name"
                        class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
                    />
                    <label for="editDriveLink" class="block mt-4">Google Drive Link (Full Link):</label>
                    <input
                    type="url"
                    v-model="editPdf.driveLink"
                    id="editDriveLink"
                    required
                    placeholder="Enter Google Drive sharing link"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
                    />
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="confirmEdit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm">
              Save changes
            </button>
            <button @click="closeEditDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 sm:mt-0 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showDeleteDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Confirm Delete</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500 dark:text-gray-400">Are you sure you want to delete this PDF? This action cannot be undone.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="confirmDelete" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm">
              Delete
            </button>
            <button @click="closeDeleteDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 sm:mt-0 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
      <img :src="loadingGif" alt="Loading..." class="h-28" />
      <p class="text-white ml-3">Loading...</p>
    </div>
  </div>
</template>

<script>
import { doc, addDoc, collection, query, orderBy, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from '@/firebase/init';
import { useToast } from 'vue-toastification';
import loadingGif from '@/assets/images/loading.gif';

export default {
  data() {
    return {
      loadingGif,
      formData: {
        documentName: '',
        driveLink: '',
      },
      editPdf: {
        id: '',
        documentName: '',
        driveLink: ''
      },
      showEditDialog: false,
      showDeleteDialog: false,
      deletePdfId: '',
      loading: false,
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      pdfs: [],
    };
  },
  computed: {
    filteredPdfs() {
      let filtered = this.pdfs;

      if (this.searchQuery) {
        filtered = filtered.filter(pdf =>
          pdf.documentName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return filtered.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredPdfs.length / this.rowsPerPage);
    },
    paginationInfo() {
      const start = (this.currentPage - 1) * this.rowsPerPage + 1;
      const end = Math.min(start + this.rowsPerPage - 1, this.filteredPdfs.length);
      return `${start} - ${end} of ${this.filteredPdfs.length}`;
    }
  },
  methods: {
    async fetchPdfs() {
        const q = query(collection(db, "PDFs_File_ID"), orderBy("documentName", "asc"));
        onSnapshot(q, (querySnapshot) => {
            this.pdfs = [];
            querySnapshot.forEach((doc) => {
            const data = { 
                id: doc.id, 
                fileId: doc.data().file_id,
                documentName: doc.data().documentName 
            };
            this.pdfs.push(data);
            });
        });
    },
    async addPdf() {
      const fileId = this.formData.driveLink.match(/\/d\/(.+)\//)[1];
      this.loading = true;
      try {
        await addDoc(collection(db, "PDFs_File_ID"), {
          documentName: this.formData.documentName,
          file_id: fileId,
        });
        this.toast.success('PDF added successfully!', { timeout: 2000 });
        this.fetchPdfs();
        this.formData.documentName = '';
        this.formData.driveLink = '';
      } catch (error) {
        this.toast.error('Failed to add the PDF. Please try again.', { timeout: 2000 });
      }
      this.loading = false;
    },
    openEditDialog(pdf) {
        this.editPdf = { 
            ...pdf, 
            driveLink: `https://drive.google.com/file/d/${pdf.fileId}/view`
        };
      this.showEditDialog = true;
    },
    closeEditDialog() {
      this.showEditDialog = false;
    },
    async confirmEdit() {
      this.loading = true;
      this.closeEditDialog();
      const fileId = this.editPdf.driveLink.match(/\/d\/(.+)\//)[1];
      try {
        const pdfDoc = doc(db, "PDFs_File_ID", this.editPdf.id);
        await updateDoc(pdfDoc, {
          documentName: this.editPdf.documentName,
          file_id: fileId,
        });
        this.toast.success('PDF updated successfully!', { timeout: 2000 });
        this.fetchPdfs();
        
      } catch (error) {
        this.toast.error('Failed to update the PDF. Please try again.', { timeout: 2000 });
      }
      this.loading = false;
    },
    openDeleteDialog(id) {
      this.deletePdfId = id;
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    async confirmDelete() {
      try {
        const pdfDoc = doc(db, "PDFs_File_ID", this.deletePdfId);
        await deleteDoc(pdfDoc);
        this.toast.success('PDF deleted successfully!', { timeout: 2000 });
        this.fetchPdfs();
        this.closeDeleteDialog();
      } catch (error) {
        this.toast.error('Failed to delete the PDF. Please try again.', { timeout: 2000 });
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  mounted() {
    this.toast = useToast();
    this.fetchPdfs();
  },
};
</script>

<style scoped>
</style>
