<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">HR Templates</li>
      </ol>
    </nav>

    <div>
      <div class="sticky top-0 shadow-lg bg-white dark:bg-gray-800 p-4 mx-4 mt-4 z-10 flex flex-col md:flex-row justify-between items-center rounded-lg">
        <div class="text-center md:text-left">
          <h4 class="text-2xl md:text-xl lg:text-2xl font-semibold">HR Templates</h4>
          <p class="text-sm md:text-base lg:text-md">Please select the template that you wish to generate</p>
        </div>
        <div class="relative w-full max-w-md mt-4 md:mt-0">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search for templates..."
            class="w-full p-2 pl-10 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
          />
          <i class="fa-solid fa-magnifying-glass absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4">
        <div
          v-for="template in filteredTemplates"
          :key="template.id"
          class="bg-white dark:bg-gray-800 py-4 px-2 rounded-md shadow-md flex flex-col items-center min-h-72"
        >
          <div class="front-page text-center flex flex-col justify-between h-full">
            <div>
              <img :src="wordlogo" alt="Word Logo" class="w-1/4 mb-4 mx-auto" />
              <p class="mb-4">{{ template.title }}</p>
            </div>
            <div class="w-full mt-auto flex flex-col 2xl:flex-row 2xl:justify-between space-y-2 2xl:space-y-0 2xl:space-x-2">
              <button
                v-if="template.hasDownload"
                @click="downloadCurrentVersion(template)"
                class="text-sm lg:text-base bg-blue-500 hover:bg-blue-400 text-white text-center py-2 rounded-md w-full 2xl:w-1/2 flex items-center justify-center px-1"
              >
                <i class="fa-solid fa-download mr-1"></i>
                Download
              </button>
              <router-link
                v-else-if="template.isInternalLink"
                :to="template.link"
                class="text-sm lg:text-base bg-blue-500 hover:bg-blue-400 text-white text-center py-2 rounded-md w-full 2xl:w-1/2 flex items-center justify-center px-1"
              >
                <i class="fa-solid fa-download mr-1"></i>
                Generate
              </router-link>
              <a
                v-else
                :href="template.link"
                :download="template.hasDownload ? true : false"
                class="text-sm lg:text-base bg-blue-400 hover:bg-blue-400 text-white text-center py-2 rounded-md w-full 2xl:w-1/2 flex items-center justify-center px-1"
              >
                <i class="fa-solid fa-download mr-1"></i>
                {{ template.hasDownload ? 'Download' : 'Generate' }}
              </a>
              <router-link
                :to="{ name: 'FileUploader', params: { docName: template.docName } }"
                class="text-sm lg:text-base bg-green-500 hover:bg-green-300 text-white text-center py-2 rounded-md w-full 2xl:w-1/2 flex items-center justify-center px-1"
              >
                <i class="fa-solid fa-edit mr-1"></i>
                Edit
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useToast } from "vue-toastification";
import wordlogo from "@/assets/images/wordlogo.png";

export default {
  name: 'HRTemplates',
  data() {
    return {
      searchQuery: '',
      wordlogo,
      templates: [
        {
          id: 0,
          title: 'Acceptance of Resignation Letter',
          link: '/AcceptanceofResignationLetter',
          isInternalLink: true,
          docName: 'Acceptance-of-Resignation-Letter-Template.docx'
        },
        {
          id: 1,
          title: 'Compensation Change Letter',
          link: '/CompensationChangeLetter',
          isInternalLink: true,
          docName: 'Compensation-Change-Letter-Template.docx'
        },
        {
          id: 2,
          title: 'Employee Handbook Acknowledgement Letter',
          link: '/EmployeeHandbookAcknowledgementLetter',
          isInternalLink: true,
          docName: 'Employee-Handbook-Acknowledgement-Letter-Template.docx'
        },
        {
          id: 3,
          title: 'Employee Novation Agreement',
          link: '/EmployeeNovationAgreement',
          isInternalLink: true,
          docName: 'Employee-Novation-Agreement-Template.docx'
        },
        {
          id: 4,
          title: 'Employment Contract Letter - General',
          link: '/EmploymentContractLetterGeneral',
          isInternalLink: true,
          docName: 'Employment-Contract-Letter-General-Template.docx'
        },
        {
          id: 5,
          title: 'Employment Contract Letter - Sales',
          link: '/EmploymentContractLetterSales',
          isInternalLink: true,
          docName: 'Employment-Contract-Letter-Sales-Template.docx'
        },
        {
          id: 6,
          title: 'Employment Contract Letter - Marketing',
          link: '/EmploymentContractLetterMarketing',
          isInternalLink: true,
          docName: 'Employment-Contract-Letter-Marketing-Template.docx'
        },
        {
          id: 7,
          title: 'Employment Contract Letter - Marketing (Fixed-Term)',
          link: '/MarketingFixedTerm',
          isInternalLink: true,
          docName: 'Employment-Contract-Letter-Marketing-Fixed-Term-Template.docx'
        },
        {
          id: 8,
          title: 'Exit Interview Form',
          link: '/ExitInterviewForm',
          isInternalLink: true,
          docName: 'Exit-Interview-Form-Template.xlsx',
        },
        {
          id: 9,
          title: 'Internship Offer Letter',
          link: '/InternshipOfferLetter',
          isInternalLink: true,
          docName: 'Internship-Offer-Letter-Template.docx'
        },
        {
          id: 10,
          title: 'Job Update Letter',
          link: '/JobUpdateLetter',
          isInternalLink: true,
          docName: 'Job-Update-Letter-Template.docx'
        },
        {
          id: 11,
          title: 'Performance Improvement Plan (PIP)',
          link: "HR Templates/Performance-Improvement-Plan.docx",
          hasDownload: true,
          isInternalLink: true,
          docName: 'Performance-Improvement-Plan.docx',
          downloadUrl: ''
        },
        {
          id: 12,
          title: 'Probation Confirmation Letter',
          link: '/ProbationConfirmationLetter',
          isInternalLink: true,
          docName: 'Probation-Confirmation-Letter-Template.docx'
        },
        {
          id: 13,
          title: 'Probation Extension Letter',
          link: '/ProbationExtensionLetter',
          isInternalLink: true,
          docName: 'Probation-Extension-Letter-Template.docx'
        },
        {
          id: 14,
          title: 'Probation Termination Letter',
          link: '/ProbationTerminationLetter',
          isInternalLink: true,
          docName: 'Probation-Termination-Letter-Template.docx'
        },
        {
          id: 15,
          title: 'Show Cause Letter',
          link: '/ShowCauseLetter',
          isInternalLink: true,
          docName: 'Show-Cause-Letter-Template.docx'
        },
        {
          id: 16,
          title: 'Warning Letter - Minor Misconduct',
          link: '/WarningLetter-MinorMisconduct',
          isInternalLink: true,
          docName: 'Warning-Letter-Minor-Misconduct-Template.docx'
        },
        {
          id: 17,
          title: 'Warning Letter - Major Misconduct',
          link: '/WarningLetter-MajorMisconduct',
          isInternalLink: true,
          docName: 'Warning-Letter-Major-Misconduct-Template.docx'
        },
        {
          id: 18,
          title: 'Warning Letter - Non-Performance',
          link: '/WarningLetter-NonPerformance',
          isInternalLink: true,
          docName: 'Warning-Letter-Non-Performance-Template.docx'
        },
      ],
    };
  },
  computed: {
    filteredTemplates() {
      return this.templates.filter(template =>
        template.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    async downloadCurrentVersion(template) {
      const toast = useToast();
      try {
        const storage = getStorage();
        const fileRef = ref(storage, template.link);
        const fileURL = await getDownloadURL(fileRef);

        toast.info("Download started. Please wait...");

        const link = document.createElement("a");
        link.href = fileURL;
        link.download = template.docName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("Downloaded Successfully!");
      } catch (error) {
        console.error("Error downloading file:", error);
        toast.error("Download failed!");
      }
    },
  },
};
</script>

<style scoped>
</style>
