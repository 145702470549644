<template>
  <div
    class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
    <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <img :src="loadingGif" alt="Loading..." class="h-28" />
      <p class="text-white ml-3">Loading...</p>
    </div>

    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li><span class="mx-2">></span></li>
        <li>
          <router-link to="/EmployeeContacts" class="text-company-color-1 hover:text-company-color-2">Employee
            Contacts</router-link>
        </li>
        <li><span class="mx-2">></span></li>
        <li class="text-gray-500 dark:text-gray-400">Add Employee Contacts</li>
        <li><span class="mx-2">></span></li>
        <li>
          <router-link to="/EditEmployeeContacts" class="text-red-500 hover:text-red-300">Edit Employee
            Contacts</router-link>
        </li>
      </ol>
    </nav>

    <div>
      <div class="w-full 2xl:w-3/4 mx-auto p-2">
        <div class="flex flex-col lg:flex-row justify-between items-start lg:items-center mt-2 mb-4">
          <h2 class="font-bold text-2xl md:text-3xl mb-2 lg:mb-0">Add Employee</h2>
        </div>

        <h3 class="font-bold mb-4 text-lg md:text-xl">Please fill up the information below:</h3>
        <div class="bg-white dark:bg-gray-800 rounded-lg">
          <form @submit.prevent="addEmployee" class="space-y-4">
            <div
              class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">
              Employee Contacts</div>
            <fieldset class="rounded p-2 md:p-6">
              <div class="grid md:grid-cols-2 gap-4 mt-4 p-2">
                <div>
                  <label for="name" class="block">Name:</label>
                  <input type="text" v-model="formData.name" id="name" required placeholder="Enter employee name"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="email" class="block">Email:</label>
                  <input type="email" v-model="formData.email" id="email" required placeholder="Enter employee email"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
              </div>
              <div class="grid md:grid-cols-2 gap-4 mt-4 p-2">
                <div>
                  <label for="phone" class="block">Phone Number:</label>
                  <input type="text" v-model="formData.phone" id="phone" required
                    placeholder="Enter employee phone number"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="department" class="block">Department:</label>
                  <select v-model="formData.department" id="department" required
                    class="bg-white dark:bg-gray-800 mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    <option value="" disabled selected hidden>Select a Department</option>
                    <option value="Administration">Administration</option>
                    <option value="Finance">Finance</option>
                    <option value="Human Resource">Human Resource</option>
                    <option value="Information Technology">Information Technology</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Sales">Sales</option>
                    <option value="Operations">Operations</option>
                  </select>
                </div>
              </div>
              <div class="grid md:grid-cols-2 gap-4 mt-4 p-2">
                <div>
                  <label for="position" class="block">Position:</label>
                  <input type="text" v-model="formData.position" id="position" required
                    placeholder="Enter employee position"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="employeeNumber" class="block">Employee Number:</label>
                  <input type="text" v-model="formData.employeeNumber" id="employeeNumber" required
                    placeholder="Enter employee number"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
              </div>
            </fieldset>

            <div class="flex justify-start items-center p-8 pt-0">
              <button type="submit"
                class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded m-0 my-2 md:m-2 w-full md:w-auto mr-2"
                :disabled="loading">Add</button>
              <button type="button" @click="resetForm"
                class="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded m-0 my-2 md:m-2 w-full md:w-auto">Reset</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, addDoc, collection } from "firebase/firestore";
import { db } from '@/firebase/init';
import { useToast } from 'vue-toastification';
import loadingGif from '@/assets/images/loading.gif';

export default {
  data() {
    return {
      loadingGif,
      employees: [],
      formData: {
        name: '',
        email: '',
        phone: '',
        department: '',
        position: '',
        employeeNumber: ''
      },
      loading: false,
      serviceID: '',
      usernameTemplateID: '',
      userID: '',
      generatedPassword: ''
    };
  },
  methods: {
    async addEmployee() {
      this.loading = true;
      try {
        this.generatedPassword = this.generateRandomPassword();

        const accessRights = this.getDefaultAccessRights(this.formData.department);

        await addDoc(collection(db, "employees"), {
          name: this.formData.name,
          email: this.formData.email,
          phone: this.formData.phone,
          department: this.formData.department,
          position: this.formData.position,
          employeeNumber: this.formData.employeeNumber,
          password: this.generatedPassword,
          accessRights
        });

        this.sendWelcomeEmail();

        this.toast.success('Employee added successfully!', { timeout: 2000 });
        this.resetForm();
      } catch (error) {
        this.toast.error('Failed to add the employee. Please try again.', { timeout: 2000 });
      }
      this.loading = false;
    },
    getDefaultAccessRights(department) {
      const accessRightsMap = {
        "Administration": [
          "Make Announcement",
          "Edit Calendar",
          "Edit PDF",
          "Edit Employee Contacts"
        ],
        "Human Resource": [
          "HR Templates",
          "Job Descriptions",
          "Edit HR Form",
          "Edit Employee Contacts"
        ],
        "Finance": [
          "Edit Finance Form"
        ],
        "Information Technology": [
          "Edit Config",
          "Edit Employee Contacts"
        ]
      };
      return accessRightsMap[department] || [];
    },

    async sendWelcomeEmail() {
      try {
        const formData = new FormData();
        formData.append('name', this.formData.name);
        formData.append('email', this.formData.email);
        formData.append('password', this.generatedPassword);

        const response = await fetch('/php/SendUserPassword.php', {
          method: 'POST',
          body: formData
        });

        const result = await response.json();

        if (result.success) {
          this.toast.success(result.message, { timeout: 2000 });
        } else {
          this.toast.error(result.message, { timeout: 2000 });
        }
      } catch (error) {
        this.toast.error('Failed to send welcome email. Please try again.', { timeout: 2000 });
      }
    },
    generateRandomPassword(length = 8) {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      return password;
    },
    resetForm() {
      this.formData = {
        name: '',
        email: '',
        phone: '',
        department: '',
        position: '',
        employeeNumber: ''
      };
    },
    async fetchEmailJSConfig() {
      try {
        const docRef = doc(db, 'configuration', 'companyInfo');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data().emailJSConfig;
          if (data) {
            this.serviceID = data.serviceId || '';
            this.usernameTemplateID = data.usernameemailtemplate || '';
            this.userID = data.userId || '';
          } else {
            console.log("No emailJSConfig document found!");
          }
        } else {
          console.log("companyInfo document does not exist!");
        }
      } catch (error) {
        console.error("Error fetching emailJSConfig: ", error);
      }
    },
  },
  mounted() {
    this.toast = useToast();
    this.fetchEmailJSConfig();
  }
};
</script>

<style scoped></style>
