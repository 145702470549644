<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full ">
    <nav class= "px-2 pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
            <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
            <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">Fiscal Calendar</li>
        <li v-if="isEditCalendar"><span class="mx-2">></span></li>
        <li v-if="isEditCalendar">
          <router-link to="/EditCalendar" class="text-red-500 hover:text-red-300">Edit Fiscal Calendar</router-link>
        </li>
        </ol>
    </nav>
    <div class="items-center w-full flex flex-col justify-center">
      <div class="flex flex-col xl:flex-row justify-center p-4 w-full max-w-screen-lg">
        <div class="flex flex-col bg-white dark:bg-gray-800 p-2 rounded-lg shadow-md xl:mx-2 w-full xl:max-w-xl mb-4 xl:mb-0">
          <div class="flex flex-col lg:flex-row justify-between mb-4">
            <h2 class="font-bold text-2xl mr-2 mb-4 flex justify-center">Fiscal Calendar {{ fiscalYear+1 }}</h2>
            <div class="flex justify-center">
              <div class="flex flex-col">
                <p class="font-bold mb-1 text-xs">Legend</p>
                <div class="flex mb-1 text-xxs">
                  <div class="w-3 h-3 bg-purple-500 mr-2"></div> Payroll Payment
                </div>
                <div class="flex mb-1 text-xxs">
                  <div class="w-3 h-3 bg-cyan-500 mr-2"></div> Payroll cut-off
                </div>
                <div class="flex mb-1 text-xxs">
                  <div class="w-3 h-3 bg-blue-500 mr-2"></div> Claim cut-off
                </div>
                <div class="flex mb-1 text-xxs">
                  <div class="w-3 h-3 bg-red-500 mr-2"></div> Month End Closing Week
                </div>
                <div class="flex text-xxs">
                  <div class="w-3 h-3 bg-orange-500 mr-2"></div> Weekends of Month End Closing Week
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-center">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 p-1">
              <div v-for="(month, index) in months" :key="index" class="bg-gray-100 dark:bg-gray-700 p-2 rounded-lg text-center max-w-72">
                <h3 class="font-bold text-md mb-2 text-company-color-1">{{ month.name }} {{ month.year }}</h3>
                <ul class="weeks grid grid-cols-7 gap-1 text-xs text-center font-semibold">
                  <li>Su</li>
                  <li>Mo</li>
                  <li>Tu</li>
                  <li>We</li>
                  <li>Th</li>
                  <li>Fr</li>
                  <li>Sa</li>
                </ul>
                <div v-for="week in month.weeks" :key="week" class="grid grid-cols-7 gap-1 text-xxxs text-center ite">
                  <div
                    v-for="day in week"
                    :key="day.date"
                    :class="{
                      'text-gray-300 dark:text-gray-500': day.isPrevMonth || day.isNextMonth,
                      'bg-purple-500 text-white': day.isPayrollPayment && !day.isPrevMonth && !day.isNextMonth,
                      'bg-cyan-500 text-white': day.isPayrollCutoff && !day.isPrevMonth && !day.isNextMonth,
                      'bg-blue-500 text-white': day.isClaimCutoff && !day.isPrevMonth && !day.isNextMonth,
                      'bg-red-500 text-white': day.isMonthEndClosingWeek && !day.isPrevMonth && !day.isNextMonth,
                      'bg-orange-500 text-white': day.isMonthEndClosingWeekend && !day.isPrevMonth && !day.isNextMonth
                    }"
                    class="p-0.5 rounded-full h-5 w-5"
                  >
                    {{ day.date }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-full xl:mx-2 xl:w-1/2">
          <div class="bg-white dark:bg-gray-800 rounded-lg p-6 xl:max-w-md shadow-md text-gray-800 dark:text-gray-100 text-xs xl:text-sm space-y-8 mb-4">
            <h4 class="font-bold text-3xl text-company-color-1">Note:</h4>
            <ul class="list-disc pl-4 mt-4 text-gray-500 dark:text-gray-300">
              <li class="pb-3">
                A fiscal year is a one-year period related to a company's financial budgeting and reporting. It does not
                necessarily start at the beginning of the calendar year. {{ companyName }}’s fiscal calendar starts on 1 Feb and ends
                on 31 Jan.
              </li>
              <li class="pb-3 ">
                Cut-off date = The last day that marks the end of a period for which transactions are included in financial
                processing. In other words, transactions occurring after the cut-off date will be processed in the
                subsequent financial period. If the cut-off date falls on a company-observed public holiday, the cut-off
                date will be 1 working day before the public holiday.
              </li>
              <li class="pb-3">
                For public holidays that fall on the weekend (Saturday or Sunday), it will be observed on the following
                Monday. If the following Monday is also a company-observed Public Holiday, then the following Tuesday will
                be the replacement and so on.
              </li>
              <li class="pb-3">
                Quick link to
                <a
                  href="https://mywavesuite1.biz/MYwaveApplication/scrt/login/display/login.php"
                  target="_blank"
                  class="text-company-color-1"
                ><b><u>MyWave</u></b></a>
              </li>
            </ul>
          </div>
          <div class="bg-white dark:bg-gray-800 rounded-lg w-full overflow-x-auto xl:max-w-md p-6 shadow-md text-gray-800 dark:text-gray-100 text-xs xl:text-sm space-y-4 mx-auto lg:mx-0">
            <h4 class="font-bold text-2xl text-company-color-1">Public Holidays</h4>
            <div class="overflow-x-auto">
              <table class="min-w-full table-auto divide-y divide-gray-200 dark:divide-gray-700">
                <thead class="bg-company-color-2 rounded-t-lg">
                  <tr>
                    <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider rounded-tl-lg">Date</th>
                    <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Day</th>
                    <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider rounded-tr-lg">Holiday</th>
                  </tr>
                </thead>
                <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  <tr v-for="holiday in sortedHolidays" :key="holiday.date + holiday.day">
                    <td class="px-4 py-2 whitespace-nowrap text-xs">{{ holiday.date }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-xs">{{ holiday.day }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-xs">{{ holiday.holiday }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase/init';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { getLocalStorageWithTimeout } from '@/utils/localStorageUtil';

export default {
  name: 'FiscalCalendar',
  data() {
    return {
      companyName: '',
      fiscalYear: null,
      months: [],
      holidays: [],
      fiscalInfo: {},
      isEditCalendar: false
    };
  },
  computed: {
    sortedHolidays() {
      return this.holidays.sort((a, b) => {
        const monthOrder = {
          'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6, 
          'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
        };

        const [aDay, aMonth] = a.date.split(' ');
        const [bDay, bMonth] = b.date.split(' ');

        if (monthOrder[aMonth] !== monthOrder[bMonth]) {
          return monthOrder[aMonth] - monthOrder[bMonth];
        } else {
          return parseInt(aDay) - parseInt(bDay);
        }
      });
    }
  },
  methods: {
    async fetchCompanyName() {
      try {
        const docRef = doc(db, 'configuration', 'companyInfo');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.companyName = docSnap.data().companyName || 'Company Name';
        } else {
          console.error('No companyInfo document found!');
          this.companyName = 'Company Name'; 
        }
      } catch (error) {
        console.error('Error fetching company name:', error);
        this.companyName = 'Company Name';
      }
    },
    getFiscalYear() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      return currentDate.getMonth() >= 1 ? currentYear : currentYear - 1;
    },
    generateFiscalCalendar() {
      const fiscalYear = this.fiscalYear;
      const months = [];
      const monthNames = [
        'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January'
      ];
      const fiscalStartMonth = 1;

      for (let i = 0; i < 12; i++) {
        const monthIndex = (fiscalStartMonth + i) % 12;
        const year = monthIndex < fiscalStartMonth ? fiscalYear + 1 : fiscalYear;
        const name = monthNames[i];
        const weeks = this.generateWeeks(year, monthIndex);
        months.push({ name, year, index: monthIndex, weeks });
      }

      return months;
    },
    generateWeeks(year, month) {
      const weeks = [];
      const date = new Date(year, month, 1);
      const firstDay = date.getDay();
      const lastDate = new Date(year, month + 1, 0).getDate();
      const prevMonthLastDate = new Date(year, month, 0).getDate();
      let week = [];
      
      for (let i = firstDay - 1; i >= 0; i--) {
        week.push({ date: prevMonthLastDate - i, isPrevMonth: true });
      }

      for (let day = 1; day <= lastDate; day++) {
        week.push({ date: day, isPrevMonth: false, isNextMonth: false });
        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }

      const nextMonthDays = 7 - week.length;
      for (let i = 1; i <= nextMonthDays; i++) {
        week.push({ date: i, isNextMonth: true });
      }
      weeks.push(week);

      return weeks;
    },
    async fetchHolidays() {
      const holidaysCollection = collection(db, 'holidays');
      const holidaysSnapshot = await getDocs(holidaysCollection);
      this.holidays = holidaysSnapshot.docs.map(doc => doc.data());
      this.applyFiscalInfoToCalendar();
    },
    async fetchFiscalInfo() {
      const fiscalDocRef = doc(db, 'fiscalInfo', 'gPjFt96yjyz5Z4Fe3OLP');
      const fiscalDocSnap = await getDoc(fiscalDocRef);
      if (fiscalDocSnap.exists()) {
        this.fiscalInfo = fiscalDocSnap.data();
        this.applyFiscalInfoToCalendar();
      }
    },
    applyFiscalInfoToCalendar() {
      if (!this.fiscalInfo) return;

      const claimCutoffDayIndex = this.getDayIndex(this.fiscalInfo.claimCutoff);

      for (let month of this.months) {
        for (let week of month.weeks) {
          for (let day of week) {
            const dateStr = `${month.name} ${day.date}`;

            day.isPayrollPayment = false;
            day.isPayrollCutoff = false;
            day.isClaimCutoff = false;
            day.isMonthEndClosingWeekend = false;
            day.isMonthEndClosingWeek = false;

            if (this.fiscalInfo.payrollPayment === 'Last Day of the Month' && this.isLastDayOfMonth(day.date, month)) {
              day.isPayrollPayment = true;
            } else if (this.fiscalInfo.payrollPayment === 'Last Weekday of the Month' && this.isLastWeekdayOfMonth(day.date, month)) {
              day.isPayrollPayment = true;
            }

            if (day.isPayrollPayment) {
              continue;
            }

            if (day.date === this.fiscalInfo.payrollCutoff && !day.isPrevMonth && !day.isNextMonth) {
              day.isPayrollCutoff = true;
            }

            if (day.isPayrollCutoff) {
              continue;
            }

            const claimCutoffDates = this.getAllDaysOfSpecificDayInMonth(month.year, month.index, claimCutoffDayIndex);
            if (claimCutoffDates.includes(day.date) && !day.isPrevMonth && !day.isNextMonth) {
              day.isClaimCutoff = true;
            }

            if (day.isClaimCutoff) {
              continue;
            }

            if (this.isWeekend({ year: month.year, month: month.index, date: day.date }) && 
                this.isMonthEndClosingWeek(day.date, month, this.fiscalInfo.monthEndClosingWeek) && 
                !day.isPrevMonth && !day.isNextMonth) {
              day.isMonthEndClosingWeekend = true;
            }

            if (day.isMonthEndClosingWeekend) {
              continue;
            }

            if (this.isMonthEndClosingWeek(day.date, month, this.fiscalInfo.monthEndClosingWeek) && 
                !day.isPrevMonth && !day.isNextMonth) {
              day.isMonthEndClosingWeek = true;
            }
          }
        }
      }
    },
    getDayIndex(dayName) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return days.indexOf(dayName);
    },
    getAllDaysOfSpecificDayInMonth(year, month, dayIndex) {
      const dates = [];
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      let firstOccurrence = 1 + ((dayIndex - firstDayOfMonth + 7) % 7);

      for (let date = firstOccurrence; date <= new Date(year, month + 1, 0).getDate(); date += 7) {
        dates.push(date);
      }

      return dates;
    },
    isLastDayOfMonth(day, month) {
      const lastDate = new Date(month.year, month.index + 1, 0).getDate();
      return day === lastDate;
    },
    isWeekend(date) {
      const day = new Date(date.year, date.month, date.date).getDay();
      return day === 0 || day === 6;
    },
    isLastWeekdayOfMonth(day, month) {
      let lastDate = new Date(month.year, month.index + 1, 0);
      let lastWeekday = lastDate.getDate();

      while (lastDate.getDay() === 0 || lastDate.getDay() === 6) {
        lastDate.setDate(lastDate.getDate() - 1);
        lastWeekday = lastDate.getDate();
      }

      return day === lastWeekday;
    },
    isMonthEndClosingWeek(day, month, weekName) {
      if (!weekName) return false;
      const weekNumbers = {
        'week 1': [1, 2, 3, 4, 5, 6, 7],
        'week 2': [8, 9, 10, 11, 12, 13, 14],
        'week 3': [15, 16, 17, 18, 19, 20, 21],
        'week 4': [22, 23, 24, 25, 26, 27, 28],
      };
      return weekNumbers[weekName.toLowerCase()].includes(day);
    },
    isMonthEndClosingWeekend(day, month, weekName) {
      if (!this.isMonthEndClosingWeek(day, month, weekName)) return false;

      const date = new Date(month.year, month.index, day.date);
      return date.getDay() === 0 || date.getDay() === 6;
    }
  },
  async created() {
    await this.fetchCompanyName();
    this.fiscalYear = this.getFiscalYear();
    this.months = this.generateFiscalCalendar();
    await this.fetchFiscalInfo();
    await this.fetchHolidays();

    this.isEditCalendar = getLocalStorageWithTimeout('isEditCalendar', false);
  }
};
</script>

<style scoped>
.weeks {
  display: flex;
  justify-content: space-between;
  padding: 0;
  list-style: none;
  margin-bottom: 4px;
}
.weeks li {
  width: 14.28%;
}
.days {
  display: flex;
  flex-wrap: wrap;
}
.days div {
  width: 14.28%;
  text-align: center;
}
.table-auto {
  table-layout: auto;
}
</style>