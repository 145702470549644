<template>
  <div class="flip-clock bg-gray-100 dark:bg-gray-700">
    <div class="flip-unit" v-for="(unit, index) in timeUnits" :key="index">
      <div class="flip-card bg-white dark:bg-gray-900 dark:text-white">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <span class="time">{{ unit.current }}</span>
          </div>
        </div>
      </div>
      <div class="label text-gray-900 dark:text-gray-300">{{ unit.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalFlipClock",
  data() {
    return {
      time: new Date(),
    };
  },
  computed: {
    timeUnits() {
      return [
        {
          label: "Hours",
          current: this.padTime(this.time.getHours()),
          next: this.padTime((this.time.getHours() + 1) % 24),
        },
        {
          label: "Minutes",
          current: this.padTime(this.time.getMinutes()),
          next: this.padTime((this.time.getMinutes() + 1) % 60),
        },
        {
          label: "Seconds",
          current: this.padTime(this.time.getSeconds()),
          next: this.padTime((this.time.getSeconds() + 1) % 60),
        },
      ];
    },
  },
  methods: {
    padTime(value) {
      return value.toString().padStart(2, "0");
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.time = new Date();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.flip-clock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.flip-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.25rem;
}

.flip-card {
  width: 60px; 
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.time {
  font-size: 2rem;
}

.label {
  margin-top: 0.5rem;
  font-size: 1rem;
}
</style>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body.dark {
  background-color: #1f2937;
  color: #e5e7eb;
}

body.dark .flip-clock {
  background-color: #374151;
}

body.dark .flip-card {
  background-color: #4b5563;
  color: #e5e7eb;
}

body.dark .label {
  color: #9ca3af;
}
</style>
