<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2">
      <nav class= "pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
              <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li>
            <router-link to="/HRTemplates" class="text-company-color-1 hover:text-company-color-2">HR Templates</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Job Update Letter</li>
        </ol>
      </nav>
      <div class="flex flex-col 2xl:flex-row justify-between p-4">
        <div class="w-full 2xl:w-7/12 mx-auto lg:mr-4">
          <div class="flex justify-between items-center mb-4">
            <h2 class="font-bold text-2xl md:text-3xl">Job Update Letter</h2>
          </div>
          <h3 class="font-bold mb-4 text-lg md:text-xl">Please fill up the information below:</h3>
          <div class="bg-white dark:bg-gray-800 rounded-lg">
            <form @submit.prevent="generateDocument" class="space-y-4">
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">Employee Contacts</div>
              <fieldset class="rounded p-2 md:p-8">
                <div class="grid md:grid-cols-2 gap-4 p-4">
                  <div class="select-container">
                    <label for="employeetitle" class="block">Courtesy Title:</label>
                    <select v-model="formData.employeetitle" id="employeetitle" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div>
                    <label for="employeelastname" class="block">Last Name:</label>
                    <input type="text" v-model="formData.employeelastname" id="employeelastname" @input="() => convertToUppercase('employeelastname')" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="employeename" class="block">Full Name:</label>
                    <input type="text" v-model="formData.employeename" id="employeename" @input="() => convertToUppercase('employeename')" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="employeeic" class="block">IC Number:</label>
                    <input type="text" v-model="formData.employeeic" id="employeeic" required placeholder="XXXXXX-XX-XXXX" pattern="[0-9]{6}-[0-9]{2}-[0-9]{4}" title="Enter your IC number in the format: XXXXXX-XX-XXXX" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="employeenumber" class="block">Employee Number:</label>
                    <input type="text" v-model="formData.employeenumber" id="employeenumber" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                </div>

                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                    <div class="grid md:grid-cols-2 gap-4 p-4">
                        <div class="flex items-center w-full space-x-2">
                            <label for="employeetype" class="w-1/3">Type:</label>
                        </div>
                        <div class="flex items-center select-container1">
                            <select v-model="formData.employeetype" id="employeetype" class="custom-select mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                              <option value="Contract">Fixed Term</option>
                            </select>
                            <div class="icon-container1 absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                              <i class="fa-solid fa-caret-down"></i>
                            </div>
                            <button @click="toggleNewType" id="newtype" class="bg-company-color-1 border-2 border-[#b48c6c] rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">New</button>
                        </div>        
                    </div>

                    <div v-if="showNewType" class="grid md:grid-cols-2 gap-4 p-4">                     
                        <div class="flex items-center w-full space-x-2">
                            <label for="employeetypenew" class="w-1/3">Type (New):</label>
                        </div>
                        <div class="flex items-center select-container1">
                          <select v-model="formData.employeetypenew" id="employeetypenew" class="custom-select mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                            <option value="Contract">Fixed Term</option>
                          </select>
                          <div class="icon-container1 absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                            <i class="fa-solid fa-caret-down"></i>
                          </div>
                        <button @click="clearNewType" id="clearnewtype" class="bg-company-color-1 border-2 border-company-color-1 rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">Clear</button>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                    <div class="grid md:grid-cols-2 gap-4 p-4">
                        <div class="flex items-center w-full space-x-2">
                            <label for="jobgrade" class="w-1/3">Job Grade:</label>
                        </div>
                        <div class="flex items-center select-container1">
                            <select v-model="formData.jobgrade" id="jobgrade" class="custom-select mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                              <option value="-">-</option>
                              <option value="(A3)">A3</option>
                              <option value="(B1)">B1</option>
                              <option value="(B2)">B2</option>
                              <option value="(B3)">B3</option>
                              <option value="(C1)">C1</option>
                              <option value="(C2)">C2</option>
                              <option value="(C3)">C3</option>
                              <option value="(D1)">D1</option>
                              <option value="(D2)">D2</option>
                              <option value="(D3)">D3</option>
                            </select>
                            <div class="icon-container1 absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                              <i class="fa-solid fa-caret-down"></i>
                            </div>
                            <button @click="toggleNewJobGrade" id="newtype" class="bg-company-color-1 border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">New</button>
                        </div>
                    </div>

                    <div v-if="showNewJobGrade" class="grid md:grid-cols-2 gap-4 p-4">    
                      <div class="flex items-center w-full space-x-2">
                            <label for="newjobgrade" class="w-1/3">Job Grade (New):</label>
                        </div>
                        <div class="flex items-center select-container1">
                            <select v-model="formData.newjobgrade" id="newjobgrade" class="custom-select mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                              <option value="-">-</option>
                              <option value="(A3)">A3</option>
                              <option value="(B1)">B1</option>
                              <option value="(B2)">B2</option>
                              <option value="(B3)">B3</option>
                              <option value="(C1)">C1</option>
                              <option value="(C2)">C2</option>
                              <option value="(C3)">C3</option>
                              <option value="(D1)">D1</option>
                              <option value="(D2)">D2</option>
                              <option value="(D3)">D3</option>
                            </select>
                            <div class="icon-container1 absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                              <i class="fa-solid fa-caret-down"></i>
                            </div>
                            <button @click="clearNewJobGrade" id="clearnewjobgrade" class="bg-company-color-1 border-2 border-company-color-1 rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">Clear</button>
                        </div>            
                    </div>
                </fieldset>

                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                    <div class="grid md:grid-cols-2 gap-4 p-4">
                        <div class="flex items-center w-full space-x-2">
                            <label for="jobtitle" class="w-1/3">Job Title:</label>
                        </div>
                        <div class="flex items-center">
                            <input type="text" v-model="formData.jobtitle" id="jobtitle" required class="mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                            <button @click="toggleNewJobTitle" id="newtype" class="bg-company-color-1 border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">New</button>
                        </div>
                    </div>
                    <div v-if="showNewJobTitle" class="grid md:grid-cols-2 gap-4 p-4">    
                      <div class="flex items-center w-full space-x-2">
                            <label for="jobtitlenew" class="w-1/3">Job Title (New):</label>
                        </div>
                        <div class="flex items-center">
                            <input type="text" v-model="formData.jobtitlenew" id="jobtitlenew" class="mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                            <button @click="clearNewJobTitle" id="clearnewjobtitle" class="bg-company-color-1 border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">Clear</button>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                    <div class="grid md:grid-cols-2 gap-4 p-4">
                        <div class="flex items-center w-full space-x-2">
                            <label for="department" class="w-1/3">Department:</label>
                        </div>
                        <div class="flex items-center select-container1">
                            <select v-model="formData.department" id="department" class="custom-select mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                                <option value="Administration">Administration</option>
                                <option value="Finance">Finance</option>
                                <option value="Human Resources">Human Resources</option>
                                <option value="Information Technology">Information Technology</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Sales">Sales</option>
                            </select>
                            <div class="icon-container1 absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                              <i class="fa-solid fa-caret-down"></i>
                            </div>
                            <button @click="toggleNewDepartment" id="newtype" class="bg-company-color-1  border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">New</button>
                        </div>
                    </div>
                    <div v-if="showNewDepartment" class="grid md:grid-cols-2 gap-4 p-4">    
                        <div class="flex items-center w-full space-x-2">
                            <label for="departmentnew" class="w-1/3">Department (New):</label>
                        </div>
                        <div class="flex items-center select-container1">
                            <select v-model="formData.departmentnew" id="departmentnew" class="custom-select mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                              <option value="" selected hidden>Select a department</option>
                              <option value="Administration">Administration</option>
                              <option value="Finance">Finance</option>
                              <option value="Human Resource">Human Resource</option>
                              <option value="Information Technology">Information Technology</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Sales">Sales</option>
                            </select>
                            <div class="icon-container1 absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                              <i class="fa-solid fa-caret-down"></i>
                            </div>
                            <button @click="clearNewDepartment" id="clearnewdepartment" class="bg-company-color-1  border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">Clear</button>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                    <div class="grid md:grid-cols-2 gap-4 p-4">
                        <div class="flex items-center">
                            <label for="reportingmanager" class="w-2/3">Reporting Manager Position:</label>
                        </div>
                        <div class="flex items-center">
                            <input type="text" v-model="formData.reportingmanager" id="reportingmanager" required class="mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                            <button @click="toggleNewReportingManager" id="newtype" class="bg-company-color-1  border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">New</button>
                        </div>
                    </div>
                    <div v-if="showNewReportingManager" class="grid md:grid-cols-2 gap-4 p-4"> 
                      <div class="flex items-center">
                            <label for="reportingmanagernew" class="w-2/3">Reporting Manager Position (New):</label>
                        </div>
                        <div class="flex items-center">
                            <input type="text" v-model="formData.reportingmanagernew" id="reportingmanagernew" class="mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                            <button @click="clearNewReportingManager" id="clearnewreportingmanager" class="bg-company-color-1  border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">Clear</button>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                    <div class="grid md:grid-cols-2 gap-4 p-4">
                        <div class="flex items-center">
                            <label for="location" class="w-2/3">Location:</label>
                        </div>
                        <div class="flex items-center">
                            <input type="text" v-model="formData.location" id="location" required class="mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                            <button @click="toggleNewLocation" id="newtype" class="bg-company-color-1  border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">New</button>
                        </div>
                    </div>
                    <div v-if="showNewLocation" class="grid md:grid-cols-2 gap-4 p-4"> 
                      <div class="flex items-center">
                            <label for="locationnew" class="w-2/3">Location (New):</label>
                        </div>
                        <div class="flex items-center">
                            <input type="text" v-model="formData.locationnew" id="locationnew" class="mt-1 mr-4 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                            <button @click="clearNewLocation" id="clearnewlocation" class="bg-company-color-1 border-2 border-company-color-1  rounded text-white text-center text-xs h-[35px] w-[60px] flex items-center justify-center cursor-pointer">Clear</button>
                        </div>
                    </div>
                </fieldset>
                
                <div class="grid md:grid-cols-2 gap-4 mt-4 p-4">
                  <div>
                    <label for="monthlybasesalary" class="block">Monthly Base Salary (RM):</label>
                    <input type="text" v-model="formData.monthlybasesalary" id="monthlybasesalary" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="monthlyallowance" class="block">Monthly Allowance (RM):</label>
                    <input type="text" v-model="formData.monthlyallowance" id="monthlyallowance" placeholder="<Optional>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>  
                  <div>
                    <label for="basesalaryincreaseamount" class="block">Base Salary Increase Amount (RM):</label>
                    <input type="text" v-model="formData.basesalaryincreaseamount" id="basesalaryincreaseamount" placeholder="<Optional>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="allowanceincreaseamount" class="block">Allowance Increase Amount (RM):</label>
                    <input type="text" v-model="formData.allowanceincreaseamount" id="allowanceincreaseamount" placeholder="<Optional>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                </div>

                <div class="p-4 mt-4"> 
                  <div class="grid md:grid-cols-2 gap-4 mt-4">
                    <div>
                      <label for="effectivedate" class="block">Effective Date:</label>
                      <input type="text" v-model="formData.effectivedate" id="effectivedate" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                    </div>
                    <div>
                      <label for="datetoday" class="block">Date of this Letter:</label>
                      <input type="text" v-model="formData.datetoday" id="datetoday" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                    </div>
                  </div>
                </div>
              </fieldset>
  
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">Company Information</div>
              <fieldset class="rounded p-2 md:p-8">
                <div class="grid md:grid-cols-2 gap-4 mt-2 p-4">
                  <div class="select-container">
                    <label for="companyname" class="block">Company Name:</label>
                    <select v-model="formData.companyname" id="companyname" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Vilor Berhad">Vilor Berhad</option>
                      <option value="Vilor Partners Berhad">Vilor Partners Berhad</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                      <i class="fa-solid fa-caret-down"></i>
                    </div>
                  </div>
                  <div>
                    <label for="companynamehereinreferredtoas" class="block">Company Name hereinafter referred to as:</label>
                    <input type="text" v-model="formData.companynamehereinreferredtoas" id="companynamehereinreferredtoas" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="signingname" class="block">Signing Name:</label>
                    <input type="text" v-model="formData.signingname" id="signingname" @input="() => convertToUppercase('signingname')" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="signingposition" class="block">Signing Position:</label>
                    <input type="text" v-model="formData.signingposition" id="signingposition" @input="() => convertToUppercase('signingposition')" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                </div>
              </fieldset>
  
              <!-- Buttons -->
              <div class="flex justify-between items-center p-8 pt-0">
                <div>
                  <button type="submit" class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto">Download Document</button>
                  <input type="reset" class="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto" value="Reset">
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="w-full 2xl:w-5/12 mx-auto flex 2xl:justify-end 2xl:mt-20 ">
          <div class="max-w-md h-fit bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 rounded-lg p-8 mt-4 2xl:sticky 2xl:top-10">
            <h3 class="font-bold text-3xl">Note:</h3>
            <ul class="list-disc pl-4 mt-4">
              <li class="pb-3">The required information shall be completed to generate the <span class="text-company-color-1"><b>Compensation Change Letter</b></span> in Microsoft Word.</li>
              <li class="pb-3">Please review the document before printing it out for signature.</li>
              <li class="pb-3">The signed copy shall be kept in <span class="text-company-color-1"><u><a href="https://vilor2016.sharepoint.com/sites/VilorBerhad/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FVilorBerhad%2FShared%20Documents%2FHR&viewid=cd7c884f%2Da1f7%2D45d2%2D8c75%2D7dadd4531fe9" target="_blank">Vilor's SharePoint</a></u></span> for record retention.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import { ref, onMounted } from 'vue';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
  
  export default {
    setup() {
      const formData = ref({
        employeetitle: 'Mr',
        employeelastname: '',
        employeename: '',
        employeeic: '',
        employeenumber: '',
        jobtitle: '',
        jobtitlenew: '',
        employeetype: '',
        employeetypenew: '',
        jobgrade: '',
        newjobgrade: '',
        department: '',
        departmentnew: '',
        reportingmanager: '',
        reportingmanagernew: '',
        location: '',
        locationnew: '',
        monthlybasesalary: '',
        monthlyallowance: '',
        basesalaryincreaseamount: '',
        allowanceincreaseamount: '',
        effectivedate: '',
        datetoday: '',
        companyname: 'Vilor Berhad',
        companynamehereinreferredtoas: '',
        signingname: '',
        signingposition: ''
      });

      const today = new Date();
      formData.value.datetoday = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

      const showNewType = ref(false);
      const showNewJobGrade = ref(false);
      const showNewJobTitle = ref(false);
      const showNewDepartment = ref(false);
      const showNewReportingManager = ref(false);
      const showNewLocation = ref(false);

      const toggleNewType = () => {
        showNewType.value = true;
      };

      const toggleNewJobGrade = () => {
        showNewJobGrade.value = true;
      };

      const toggleNewJobTitle = () => {
        showNewJobTitle.value = true;
      };

      const toggleNewDepartment = () => {
        showNewDepartment.value = true;
      };

      const toggleNewReportingManager = () => {
        showNewReportingManager.value = true;
      };

      const toggleNewLocation = () => {
        showNewLocation.value = true;
      };
      
      const clearNewType = () => {
        formData.value.employeetypenew = '';
        showNewType.value = false;
      };

      const clearNewJobGrade = () => {
        formData.value.newjobgrade = '';
        showNewJobGrade.value = false;
      };

      const clearNewJobTitle = () => {
        formData.value.jobtitlenew = '';
        showNewJobTitle.value = false;
      };

      const clearNewDepartment = () => {
        formData.value.departmentnew = '';
        showNewDepartment.value = false;
      };

      const clearNewReportingManager = () => {
        formData.value.reportingmanagernew = '';
        showNewReportingManager.value = false;
      };

      const clearNewLocation = () => {
        formData.value.locationnew = '';
        showNewLocation.value = false;
      };
  
      const toast = useToast();
  
      const convertToUppercase = (key) => {
        formData.value[key] = formData.value[key].toUpperCase();
      };
  
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      };
  
      const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      };
  
      const formatFullDate = (dateString) => {
        const date = parseDate(dateString);
        if (!isNaN(date)) {
          const day = date.getDate();
          const month = date.toLocaleString('en-GB', { month: 'long' });
          const year = date.getFullYear();
          const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
              case 1: return 'st';
              case 2: return 'nd';
              case 3: return 'rd';
              default: return 'th';
            }
          };
          return `${day}${daySuffix(day)} ${month} ${year}`;
        }
        return '';
      };
  
      const formatShortDate = (dateString) => {
        const date = parseDate(dateString);
        if (!isNaN(date)) {
          const day = String(date.getDate()).padStart(2, '0');
          const month = date.toLocaleString('en-GB', { month: 'short' });
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        }
        return '';
      };

      const formatCurrency = (amount) => {
        return amount.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' }).replace('MYR', 'RM');
      };
  
      const fetchTemplateUrl = async () => {
        const storage = getStorage();
        const templateRef = storageRef(storage, 'HR Templates/Job-Update-Letter-Template.docx');
        return await getDownloadURL(templateRef);
      };
  
      const generateDocument = async () => {
        try {
          toast.info('Download started. Please wait...');
          const templateUrl = await fetchTemplateUrl();
          const response = await fetch(templateUrl);
          const templateArrayBuffer = await response.arrayBuffer();
          const zip = new PizZip(templateArrayBuffer);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true
          });

          const formattedEmployeeName = formData.value.employeename.toUpperCase();
          const newemployeetype = formData.value.employeetypenew || '-';
          const newjobtitle = formData.value.jobtitlenew || '-';
          const jobgradenew = formData.value.newjobgrade || '-';
          const newdepartment = formData.value.departmentnew || '-';
          const newreportingmanager = formData.value.reportingmanagernew || '-';
          const newlocation = formData.value.locationnew || '-';

          const formattedeffectivedateShort = formatShortDate(formData.value.effectivedate);
          const formattedeffectivedate = formatFullDate(formData.value.effectivedate);
          const dateToday = formatShortDate(formData.value.datetoday);
          const upperCompanyName = formData.value.companyname.toUpperCase();
          const formattedLastName = capitalizeFirstLetter(formData.value.employeelastname);
  
          const monthlyBasesalary = parseFloat(formData.value.monthlybasesalary) || 0;
          const monthlyAllowance = parseFloat(formData.value.monthlyallowance) || 0;
          const baseSalaryIncreaseAmount = parseFloat(formData.value.basesalaryincreaseamount) || 0;
          const allowanceIncreaseAmount = parseFloat(formData.value.allowanceincreaseamount) || 0;

          let newMonthlyBaseSalary = monthlyBasesalary + baseSalaryIncreaseAmount;
          let newMonthlyAllowance = monthlyAllowance + allowanceIncreaseAmount;

          let baseSalaryIncreasePercentage = (baseSalaryIncreaseAmount / monthlyBasesalary) * 100;

          const dataToSet = {
            ...formData.value,
            employeename: formattedEmployeeName,
            employeetypenew: newemployeetype,
            jobtitlenew: newjobtitle,
            newjobgrade: jobgradenew,
            departmentnew: newdepartment,
            reportingmanagernew: newreportingmanager,
            locationnew: newlocation,
            effectivedate: formattedeffectivedate,
            formattedeffectivedate: formattedeffectivedateShort,
            datetoday: dateToday,
            uppercompanyname: upperCompanyName,
            employeelastname: formattedLastName,
            monthlybasesalary: formatCurrency(monthlyBasesalary),
            monthlyallowance: monthlyAllowance === 0 ? '-' : formatCurrency(monthlyAllowance),
            newmonthlybasesalary: formatCurrency(newMonthlyBaseSalary),
            newmonthlyallowance: newMonthlyAllowance === 0 ? '-' : formatCurrency(newMonthlyAllowance),
            basesalaryincreasepercentage: baseSalaryIncreasePercentage === 0 ? '-' : baseSalaryIncreasePercentage.toFixed(2) + '%',
            allowanceincreaseamount: allowanceIncreaseAmount === 0 ? '-' : formatCurrency(allowanceIncreaseAmount),
            basesalaryincreaseamount: baseSalaryIncreaseAmount === 0 ? '-' : formatCurrency(baseSalaryIncreaseAmount),
          };
  
          doc.setData(dataToSet);
          doc.render();
  
          const out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          });
  
          saveAs(out, `Job-Update-Letter_${formData.value.employeename.toUpperCase()}.docx`);
          toast.success('Document downloaded successfully!');
        } catch (error) {
          toast.error('Error generating document');
          console.error('Error generating document:', error);
        }
      };
  
      onMounted(() => {
        flatpickr('.datepicker', {
          dateFormat: 'd/m/Y',
          static: true
        });
      });
  
      return {
        formData,
        showNewType,
        showNewJobGrade,
        showNewJobTitle,
        showNewDepartment,
        showNewReportingManager,
        showNewLocation,
        convertToUppercase,
        generateDocument,
        toggleNewType,
        toggleNewJobGrade,
        toggleNewJobTitle,
        toggleNewDepartment,
        toggleNewReportingManager,
        toggleNewLocation,
        clearNewType,
        clearNewJobGrade,
        clearNewJobTitle,
        clearNewDepartment,
        clearNewReportingManager,
        clearNewLocation
      };
    }
  };
</script>

<style scoped>

.fields {
  margin-bottom: 10px;
}
.message h5 {
  color: red;
}

.select-container {
  position: relative;
  display: inline-block;
}

.select-container1 {
  position: relative;
}

.icon-container {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  right: 10px;
  pointer-events: none;
}

.icon-container1 {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 70px;
  pointer-events: none;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2rem;
}
</style>
  