<template>
  <div
    :class="[open ? 'w-full md:w-72 z-20 overflow-y-scroll' : 'w-14 md:w-20 z-10 overflow-y-scroll',
      'bg-gray-800 h-full flex flex-col p-2 md:p-5 fixed md:relative duration-300 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]']"
    @mouseenter="handleMouseEnter"
  >
    <div class="relative flex items-center text-2xl text-gray-300 h-10 whitespace-nowrap">
      <button @click="toggleSidebar" class="absolute left-0 top-0 w-10 h-10 flex justify-center items-center">
        <div id="nav-icon3" :class="{ 'open': open }" class="relative w-full h-full">
          <span class="bg-company-color-1"></span>
          <span class="bg-company-color-1"></span>
          <span class="bg-company-color-1"></span>
          <span class="bg-company-color-1"></span>
        </div>
      </button>
      <div :class="['ml-12', { hidden: !open }, 'duration-200']">
        <b>Table of Content</b>
      </div>
    </div>

    <ul class="pt-8 whitespace-nowrap flex-grow overflow-auto overflow-x-hidden [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
      <li
        v-for="(Menu, index) in filteredMenus"
        :key="index"
        class="group rounded-md cursor-pointer hover:bg-gray-600 text-white text-sm mt-2 mb-2"
      >
        <!-- Dropdown Items -->
        <div v-if="Menu.subMenus">
          <div
            class="flex items-center justify-between p-2"
            @click="toggleDropdown(Menu.title)"
          >
            <div class="flex items-center gap-x-4">
              <div class="w-6 h-5 flex justify-center items-center">
                <i :class="Menu.icon" class="text-lg"></i>
              </div>
              <span :class="[{ hidden: !open }, 'origin-left duration-200']">{{ Menu.title }}</span>
            </div>
            <i
              v-if="open"
              :class="[dropdowns[Menu.title] ? 'fas fa-chevron-up' : 'fas fa-chevron-down']"
              class="ml-auto"
            ></i>
          </div>
          <!-- Submenu List -->
          <ul
            :class="[{ block: dropdowns[Menu.title], hidden: !dropdowns[Menu.title] }]"
            class="ml-6 mt-2"
          >
            <li
              v-for="(subMenu, subIndex) in Menu.subMenus"
              :key="subIndex"
              class="flex items-center gap-x-4 w-full py-2 px-2 hover:bg-gray-700 rounded-md"
            >
              <router-link
                :to="subMenu.link"
                class="flex items-center gap-x-4 w-full"
              >
                <div class="w-6 flex justify-center h-5 items-center">
                  <i :class="subMenu.icon" class="text-lg"></i>
                </div>
                <span :class="[{ hidden: !open }, 'origin-left duration-200']">{{ subMenu.title }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- Non-Dropdown Items -->
        <div v-else>
          <router-link
            :to="Menu.link"
            class="flex items-center w-full gap-x-4 p-2 rounded-md hover:bg-gray-600"
          >
            <div class="w-6 h-5 flex justify-center items-center">
              <i :class="Menu.icon" class="text-lg"></i>
            </div>
            <span :class="[{ hidden: !open }, 'origin-left duration-200']">{{ Menu.title }}</span>
          </router-link>
        </div>
      </li>
    </ul>

    <div class="w-full p-1 flex flex-col text-white bg-gray-600 rounded-lg justify-between">
      <div class="flex items-center gap-x-4 whitespace-nowrap h-10">
        <img
          :src="userIcon"
          alt="User Icon"
          class="h-8 w-8 rounded-full border-2 border-gray-600"
        />
        <div v-if="open" class="flex-1">
          <p class="font-semibold text-sm break-words whitespace-normal">
            {{ userName }}
          </p>
        </div>
        <div v-if="open" class="text-sm">
          <router-link to="/ChangePassword" class="flex items-center gap-x-4 py-2 px-2 hover:bg-gray-700 rounded-md transition-all duration-300">
            <i class="fas fa-cog text-xl"></i>
          </router-link>
        </div>
      </div>

      <div class="flex items-center gap-x-4 py-2 px-2 mt-2 hover:bg-gray-700 rounded-md transition-all duration-300">
        <i class="fas fa-sign-out-alt text-xl"></i>
        <button v-if="open" @click="logout" class="text-sm flex-1 text-left">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue';
import { getLocalStorage,setLocalStorage, getLocalStorageWithTimeout, setLocalStorageWithTimeout } from '@/utils/localStorageUtil';
import { eventBus } from '@/utils/eventBus';
import { useRouter } from 'vue-router';
import { db } from '@/firebase/init';
import { query, where, getDocs, collection, onSnapshot } from "firebase/firestore";
import userIcon from '@/assets/images/user.png';

export default {
  setup() {
    const open = ref(getLocalStorage('sidebarOpen', true));
    const dropdowns = reactive({});
    const rememberedDropdown = ref(null);
    const userName = ref('Username');

    const isEditConfig = ref(false);
    const isEditPDF = ref(false);
    const isHRTemplates = ref(false);
    const isJobDescriptions = ref(false);

    const router = useRouter();

    const accessRightVariables = {
      "Edit Config": isEditConfig,
      "Make Announcement": ref(false),
      "Edit Calendar": ref(false),
      "Edit PDF": isEditPDF,
      "Edit Employee Contacts": ref(false),
      "HR Templates": isHRTemplates,
      "Job Descriptions": isJobDescriptions,
      "Edit HR Form": ref(false),
      "Edit Finance Form": ref(false)
    };

    const fetchUserName = async () => {
      try {
        const employeeEmailObject = getLocalStorage('loggedInEmployeeEmail', {});
        const employeeEmail = employeeEmailObject.value;

        if (employeeEmail) {
          const q = query(collection(db, "employees"), where("email", "==", employeeEmail));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              userName.value = doc.data().name;
            });

            const userDoc = querySnapshot.docs[0];
            onSnapshot(userDoc.ref, (doc) => {
              const data = doc.data();

              setLocalStorageWithTimeout('loggedInAccessRights', data.accessRights);

              for (const [right, variable] of Object.entries(accessRightVariables)) {
                const hasAccess = data.accessRights.includes(right);
                setLocalStorageWithTimeout(`is${right.replace(/\s+/g, '')}`, hasAccess);
                variable.value = hasAccess;
              }
            });
          } else {
            console.log("No such document!");
          }
        } else {
          console.log("Employee number not found in local storage");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    onMounted(() => {
      fetchUserName();
      if (!open.value && rememberedDropdown.value) {
        for (let key in dropdowns) {
          dropdowns[key] = false;
        }
      }
    });

    const filteredMenus = computed(() => {
      return Menus.map(menu => {
        if (menu.subMenus) {
          const filteredSubMenus = menu.subMenus.filter(subMenu => {
            switch (subMenu.title) {
              case "Configurations":
                return isEditConfig.value;
              case "PDFs Documents":
                return isEditPDF.value;
              case "HR Templates":
                return isHRTemplates.value;
              case "Job Description Library":
                return isJobDescriptions.value;
              default:
                return true;
            }
          });
          if (filteredSubMenus.length > 0) {
            return { ...menu, subMenus: filteredSubMenus };
          }
          return null;
        } else {
          return menu;
        }
      }).filter(menu => menu !== null);
    });

    const toggleDropdown = (title) => {
      const newDropdowns = {};
      for (let key in dropdowns) {
        newDropdowns[key] = false;
      }
      newDropdowns[title] = !dropdowns[title];
      Object.assign(dropdowns, newDropdowns);
      rememberedDropdown.value = newDropdowns[title] ? title : null;
      if (!open.value) {
        open.value = true;
      }
      setLocalStorage('sidebarOpen', open.value);
      eventBus.value.publish('sidebarToggle', open.value);
    };

    const toggleSidebar = () => {
      if (open.value) {
        rememberedDropdown.value = Object.keys(dropdowns).find((key) => dropdowns[key]);
        for (let key in dropdowns) {
          dropdowns[key] = false;
        }
      } else {
        if (rememberedDropdown.value) {
          dropdowns[rememberedDropdown.value] = true;
        }
      }
      open.value = !open.value;
      setLocalStorage('sidebarOpen', open.value);
      eventBus.value.publish('sidebarToggle', open.value);
    };

    const handleMouseEnter = () => {
      if (!open.value) {
        open.value = true;
        if (rememberedDropdown.value) {
          dropdowns[rememberedDropdown.value] = true;
        }
        eventBus.value.publish('sidebarToggle', open.value);
      }
    };

    const handleMouseLeave = () => {
      if (open.value && rememberedDropdown.value) {
        dropdowns[rememberedDropdown.value] = false;
        open.value = false;
        eventBus.value.publish('sidebarToggle', open.value);
      }
    };

    const handleLinkClick = () => {
      if (window.innerWidth < 768) {
        toggleSidebar();
      }
    };

    const logout = () => {
      localStorage.removeItem('isLoginAuthenticated');
      localStorage.removeItem('loggedInEmployeeEmail');
      router.push('/');
    };

    const Menus = [
      { title: "Dashboard", icon: 'fas fa-table-cells-large', link: "/Dashboard" },
      {
        title: "Our Company", icon: 'fas fa-briefcase', subMenus: [
          { title: "Introduction", link: "/Introduction", icon: 'fas fa-info-circle' },
          { title: "Mission and Vision", link: "/MissionVision", icon: 'fas fa-globe' },
          { title: "Founder", link: "/Founder", icon: 'fas fa-user-circle' },
          { title: "Core Values", link: "/CoreValues", icon: 'fas fa-shield' },
          { title: "Company Structure", link: "/CompanyStructure", icon: 'fas fa-chart-simple' },
          { title: "Business Overview", link: "/BusinessOverview", icon: 'fas fa-th' },
          { title: "Employee Contacts", link: "/EmployeeContacts", icon: 'fas fa-address-book' }
        ]
      },
      { title: "Announcement", icon: 'fas fa-bullhorn', link: "/Announcement" },
      {
        title: "Calendar", icon: 'fas fa-calendar-check', subMenus: [
          { title: "Fiscal Calendar", link: "/FiscalCalendar", icon: 'fas fa-calendar' },
          { title: "Event Calendar", link: "#", icon: 'fas fa-calendar-alt' }
        ]
      },
      {
        title: "Employee Form", icon: 'fas fa-users', subMenus: [
          { title: "Finance Form", link: "/FinanceForms", icon: 'fas fa-file-invoice-dollar' },
          { title: "HR Form", link: "/HRForms", icon: 'fas fa-file-word' }
        ]
      },
      { title: "Organization Chart", icon: 'fas fa-sitemap', link: "/OrganizationChart" },
      { title: "Performance Metrics", icon: 'fas fa-line-chart', link: "#" },
      {
        title: "Process Documentation", icon: 'fas fa-wrench', subMenus: [
          { title: "Objectives", link: "#", icon: 'fas fa-bullseye' },
          { title: "Change Control", link: "#", icon: 'fas fa-refresh' }
        ]
      },
      {
        title: "Administration", icon: 'fas fa-file', subMenus: [
          { title: "PDFs Documents", link: "/EditPDFs", icon: 'fas fa-file-pdf ' },
        ]
      },
      {
        title: "Facility", icon: 'fas fa-building', subMenus: [
          { title: "Facility", link: "#", icon: 'fas fa-building' },
          { title: "Procurement", link: "#", icon: 'fas fa-shopping-cart' }
        ]
      },
      {
        title: "Finance", icon: 'fas fa-hand-holding-usd  ', subMenus: [
          { title: "Processes", link: "/FinanceProcesses", icon: 'fas fa-cubes' },
          { title: "Policies", link: "/FinancePolicies", icon: 'fas fa-file-alt' }
        ]
      },
      {
        title: "Human Resource", icon: 'fas fa-user', subMenus: [
          { title: "Employee Handbook", link: "/EmployeeHandbook", icon: 'fas fa-book' },
          { title: "Competency Model", link: "#", icon: 'fas fa-bullseye' },
          { title: "Job Description Library", link: "/JobDescriptionLibrary", icon: 'fas fa-file-alt' },
          { title: "HR Templates", link: "/HRTemplates", icon: 'fas fa-file-word' },
          { title: "Processes", link: "/HRProcesses", icon: 'fas fa-cubes' },
          { title: "Learning & Development", link: "#", icon: 'fas fa-highlighter' }
        ]
      },
      {
        title: "Information Technology", icon: 'fas fa-desktop', subMenus: [
          { title: "Configurations", link: "/Configurations", icon: 'fas fa-cog' },
        ]
      },
      {
        title: "Sales and Marketing", icon: 'fas fa-chart-line', subMenus: [
          { title: "Starfish", link: "#", icon: 'fas fa-star' },
          { title: "Angel Investor Club (AIC)", link: "#", icon: 'fas fa-bar-chart' },
          { title: "I'm A Miracle", link: "#", icon: 'fas fa-user-check' },
          { title: "WhatSender", link: "#", icon: 'fas fa-share-square' }
        ]
      }
    ];

    return {
      open,
      dropdowns,
      rememberedDropdown,
      filteredMenus,
      toggleDropdown,
      toggleSidebar,
      handleMouseEnter,
      handleMouseLeave,
      handleLinkClick,
      logout,
      userName,
      userIcon,
    };
  }
};
</script>

<style scoped>
#nav-icon3 {
  width: 30px;
  height: 22px;
  position: relative;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 9px;
}

#nav-icon3 span:nth-child(4) {
  top: 18px;
}

#nav-icon3.open span:nth-child(1) {
  top: 9px;
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(2) {
  opacity: 0;
}

#nav-icon3.open span:nth-child(3) {
  opacity: 0;
}

#nav-icon3.open span:nth-child(4) {
  top: 9px;
  transform: rotate(-45deg);
}
</style>
