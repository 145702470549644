<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full">
    <div>
      <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
            <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">HR Forms</li>
        </ol>
      </nav>

      <div class="sticky top-0 shadow-lg bg-white dark:bg-gray-800 p-4 mx-4 mt-4 z-10 flex flex-col md:flex-row justify-between items-center rounded-lg">
        <div class="text-center md:text-left">
          <h4 class="text-2xl md:text-xl lg:text-2xl font-semibold">HR Forms</h4>
          <p class="text-sm md:text-base lg:text-md">Please select the form that you wish to fill up</p>
        </div>
        <div class="relative w-full max-w-md mt-4 md:mt-0">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search for forms..."
            class="w-full p-2 pl-10 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
          />
          <i class="fa-solid fa-magnifying-glass absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 gap-4 p-4">
        <div
          v-for="template in filteredTemplates"
          :key="template.id"
          class="bg-white dark:bg-gray-800 rounded-md shadow-md flex flex-col items-center min-h-72"
        >
          <div class="front-page text-center flex flex-col justify-between h-full w-full">
            <div>
              <img :src="template.imageSrc" alt="Form Image" class="w-full h-64 mb-4 rounded-t-lg" />
              <p class="mb-4">{{ template.title }}</p>
            </div>
            <div class="w-full mt-auto flex flex-col 2xl:flex-row 2xl:justify-center space-y-2 2xl:space-y-0 2xl:space-x-2 p-4">
              <router-link
                v-if="template.type === 'internalLink'"
                :to="template.link"
                class="text-sm lg:text-base bg-blue-500 hover:bg-blue-400 text-white text-center py-2 rounded-md w-full 2xl:w-2/3 flex items-center justify-center px-1"
              >
                <i class="fa-solid fa-external-link-alt mr-1"></i>
                Fill up
              </router-link>
              <a
                v-else-if="template.type === 'externalLink'"
                :href="template.link"
                target="_blank"
                class="text-sm lg:text-base bg-blue-500 hover:bg-blue-400 text-white text-center py-2 rounded-md w-full 2xl:w-2/3 flex items-center justify-center px-1"
              >
                <i class="fa-solid fa-external-link-alt mr-1"></i>
                Fill up
              </a>
              <button
                v-else-if="template.type === 'downloadable'"
                @click="downloadDocument(template.link)"
                class="text-sm lg:text-base bg-blue-500 hover:bg-blue-400 text-white text-center py-2 rounded-md w-full 2xl:w-2/3 flex items-center justify-center px-1"
              >
                <i class="fa-solid fa-download mr-1"></i>
                Download
              </button>
              <router-link
                v-if="isEditHRForm"
                :to="{ name: 'EditHRForms', params: { id: template.id } }"
                class="text-sm lg:text-base bg-green-500 hover:bg-green-300 text-white text-center py-2 rounded-md w-full 2xl:w-2/3 flex items-center justify-center px-1 mt-2"
              >
                <i class="fa-solid fa-edit mr-1"></i>
                Edit
              </router-link>
            </div>
          </div>
        </div>

        <router-link
          v-if="isEditHRForm"
          to="/AddHRForm"
          class="bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 py-4 px-2 rounded-md shadow-md flex flex-col items-center justify-center min-h-72"
        >
          <div class="text-center flex flex-col justify-center h-full">
            <i class="fa-solid fa-plus text-6xl text-company-color-1 mb-4"></i>
            <p class="text-lg font-semibold">Add New Form</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs } from 'firebase/firestore';
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { db } from '@/firebase/init';
import { getLocalStorageWithTimeout } from '@/utils/localStorageUtil';
import { useToast } from "vue-toastification";

export default {
  name: 'HRTemplates',
  data() {
    return {
      searchQuery: '',
      templates: [],
      isEditHRForm: false,
    };
  },
  computed: {
    filteredTemplates() {
      return this.templates.filter(template =>
        template.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    async fetchForms() {
      try {
        const querySnapshot = await getDocs(collection(db, 'HR_Forms'));
        this.templates = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error("Error fetching forms: ", error);
      }
    },
    async downloadDocument(firebasePath) {
      const toast = useToast();
      toast.info('Download started. Please wait...');

      try {
        const storage = getStorage();
        const fileRef = storageRef(storage, firebasePath);
        const url = await getDownloadURL(fileRef);

        const a = document.createElement('a');
        a.href = url;
        a.download = firebasePath.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        toast.success("Downloaded Successfully!");
      } catch (error) {
        console.error("Error downloading document: ", error);
        toast.error("Failed to download the document.");
      }
    },
  },
  mounted() {
    this.fetchForms();
    this.isEditHRForm = getLocalStorageWithTimeout('isEditHRForm', false);
  },

};
</script>

<style scoped>
</style>
