<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors md:p-2 duration-300 flex flex-col h-full">
    <nav class="px-4 pt-2 mb-1 mx-4 text-xs lg:text-md h-6">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">Mission and Vision</li>
      </ol>
    </nav>
    <div class="justify-center items-center flex 2xl:h-full bg-cover bg-center">
      <div class="max-w-4xl w-full p-4 space-y-16">
        <div class="text-center text-white py-20" :style="{ backgroundImage: `url(${missionVisionBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }">
          <h2 class="text-5xl font-bold tracking-wide text-company-color-1">{{ missionTitle }}</h2>
          <p class="mt-6 text-2xl text-gray-900">{{ missionEnglish }}</p>
          <p class="mt-2 text-xl text-gray-900">{{ missionChinese }}</p>
        </div>
        <div class="text-center text-white py-20" :style="{ backgroundImage: `url(${missionVisionBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }">
          <h2 class="text-5xl font-bold tracking-wide text-company-color-1">{{ visionTitle }}</h2>
          <p class="mt-6 text-2xl text-gray-900">{{ visionEnglish }}</p>
          <p class="mt-2 text-xl text-gray-900">{{ visionChinese }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/firebase/init';
import missionVisionBg from '@/assets/images/MissionVision/missionvisionbg.jpg';

export default {
  name: "MissionVision",
  data() {
    return {
      missionVisionBg,
      missionTitle: "M I S S I O N",
      missionEnglish: '',
      missionChinese: '',
      visionTitle: "V I S I O N",
      visionEnglish: '',
      visionChinese: '',
    };
  },
  async mounted() {
    try {
      const docRef = doc(db, "configuration", "companyInfo");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        this.missionEnglish = data.mission.english;
        this.missionChinese = data.mission.chinese;
        this.visionEnglish = data.vision.english;
        this.visionChinese = data.vision.chinese;
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
    }
  }
};
</script>

<style scoped>
</style>
