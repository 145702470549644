<template>
        <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
        <!-- Loading Animation -->
        <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
            <img :src="loadingGif" alt="Loading..." class="h-28" />
            <p class="text-white ml-3">Loading...</p>
        </div>

        <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
            <ol class="list-reset flex text-gray-700 dark:text-gray-300">
            <li>
                <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
            </li>
            <li><span class="mx-2">></span></li>
            <li>
                <router-link to="/EmployeeContacts" class="text-company-color-1 hover:text-company-color-2">Employee Contacts</router-link>
            </li>
            <li><span class="mx-2">></span></li>
            <li>
                <router-link to="/AddEmployeeContacts" class="text-company-color-1 hover:text-company-color-2">Add Employee Contacts</router-link>
            </li>
            <li><span class="mx-2">></span></li>
            <li class="text-gray-500 dark:text-gray-400">Edit Employee Contacts</li>
            </ol>
        </nav>
    
        <!-- Employee Contacts Table -->
        <div class="w-full 2xl:w-3/4 mx-auto mt-8 p-2">
            <div class="flex justify-between items-center mb-4">
            <h2 class="font-bold text-2xl md:text-3xl">Edit Employee Contacts</h2>
            </div>
            <div class="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 mb-4">
            <input
                type="text"
                v-model="searchQuery"
                placeholder="Search for employees..."
                class="flex-1 p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
            />
            <select v-model="groupByDepartment" class="flex-1 sm:w-auto bg-white dark:bg-gray-800 p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                <option value="">All Departments</option>
                <option v-for="dept in departments" :key="dept" :value="dept">{{ dept }}</option>
            </select>
            <select v-model="sortBy" class="flex-1 sm:w-auto bg-white dark:bg-gray-800 p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                <option value="name">Sort by Name</option>
                <option value="employeeNumber">Sort by Employee Number</option>
            </select>
            </div>
            <div class="overflow-x-auto">
            <div class="min-w-full inline-block align-middle overflow-hidden bg-white dark:bg-gray-800 mb-4 shadow sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600 text-xs lg:text-lg">
                <thead class="bg-gray-50 dark:bg-gray-700">
                    <tr>
                    <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Email</th>
                    <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
                    <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Department</th>
                    <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Position</th>
                    <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Employee Number</th>
                    <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Access</th>
                    <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                </thead>
                <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-600">
                    <tr v-for="employee in filteredEmployees" :key="employee.id">
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.name }}</td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.email }}</td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.phone }}</td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.department }}</td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.position }}</td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.employeeNumber }}</td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">
                        <button @click="toggleAccess(employee.id)" class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded mb-2 lg:mb-0 lg:mr-2 w-full lg:w-auto">
                        {{ expandedAccess[employee.id] ? 'Close ▲' : 'Show ▼' }}
                        </button>
                        <div v-if="expandedAccess[employee.id]">
                        <ul class="mt-2">
                            <li v-for="accessRight in allAccessRights" :key="accessRight" class="flex items-center">
                            <input type="checkbox" :checked="employee.accessRights.includes(accessRight)" disabled class="form-checkbox text-blue-500"/>
                            <label class="ml-2">{{ accessRight }}</label>
                            </li>
                        </ul>
                        </div>
                    </td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap flex flex-col lg:flex-row">
                        <button @click="openEditDialog(employee)" class="bg-green-500 hover:bg-green-300 text-white px-4 py-2 rounded mb-2 lg:mb-0 lg:mr-2 w-full lg:w-auto">Edit</button>
                        <button @click="openDeleteDialog(employee.id)" class="bg-red-500 hover:bg-red-400 text-white px-4 py-2 rounded w-full lg:w-auto">Delete</button>
                    </td>
                    </tr>
                </tbody>
                </table>
                <div class="ml-8 md:ml-0 flex justify-start md:justify-between p-4 md:items-center">
                <div class="flex flex-col md:flex-row w-fit md:w-full items-center justify-start md:justify-between">
                    <div class="text-sm lg:text-md mb-4 lg:mb-0">
                    Rows per page:
                    <select v-model="rowsPerPage" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2">
                        <option v-for="option in [5, 10, 15, 20]" :key="option" :value="option">{{ option }}</option>
                    </select>
                    </div>
                    <div class="text-sm lg:text-md">
                    <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded mr-2">Prev</button>
                    {{ paginationInfo }}
                    <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded ml-2">Next</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    
        <div v-if="showEditDialog" class="fixed z-30 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Edit Employee</h3>
                    <div class="mt-2">
                        <div class="grid md:grid-cols-2 gap-4 mt-4 p-2">
                        <div>
                            <label for="editName" class="block">Name:</label>
                            <input type="text" v-model="editEmployee.name" id="editName" required placeholder="Enter employee name" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                        </div>
                        <div>
                            <label for="editEmail" class="block">Email:</label>
                            <input type="email" v-model="editEmployee.email" id="editEmail" required placeholder="Enter employee email" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                        </div>
                        </div>
                        <div class="grid md:grid-cols-2 gap-4 mt-4 p-2">
                        <div>
                            <label for="editPhone" class="block">Phone Number:</label>
                            <input type="text" v-model="editEmployee.phone" id="editPhone" required placeholder="Enter employee phone number" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                        </div>
                        <div>
                            <label for="editDepartment" class="block">Department:</label>
                            <select v-model="editEmployee.department" id="editDepartment" required class="bg-white dark:bg-gray-800 mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                              <option value="" disabled selected hidden>Select a Department</option>
                              <option value="Administration">Administration</option>
                              <option value="Finance">Finance</option>
                              <option value="Human Resource">Human Resource</option>
                              <option value="Information Technology">Information Technology</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Sales">Sales</option>
                              <option value="Operations">Operations</option>
                            </select>
                        </div>
                        </div>
                        <div class="p-2 mt-2">
                        <label for="editPosition" class="block">Position:</label>
                        <input type="text" v-model="editEmployee.position" id="editPosition" required placeholder="Enter employee position" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                        </div>
                        <div class="p-2 mt-2">
                        <label for="editEmployeeNumber" class="block">Employee Number:</label>
                        <input type="text" v-model="editEmployee.employeeNumber" id="editEmployeeNumber" required placeholder="Enter employee number" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                        </div>
                        <div class="p-2 mt-2">
                        <label for="editAccessRights" class="block">Access Rights:</label>
                        <ul>
                            <li v-for="accessRight in allAccessRights" :key="accessRight" class="flex items-center">
                            <input type="checkbox" v-model="editEmployee.accessRights" :value="accessRight" class="form-checkbox text-blue-500" />
                            <label class="ml-2">{{ accessRight }}</label>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button @click="confirmEdit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Save changes
                </button>
                <button @click="closeEditDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                    Cancel
                </button>
                </div>
            </div>
            </div>
        </div>
    
        <div v-if="showDeleteDialog" class="fixed z-30 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Confirm Delete</h3>
                    <div class="mt-2">
                        <p class="text-sm text-gray-500 dark:text-gray-400">Are you sure you want to delete this employee? This action cannot be undone.</p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button @click="confirmDelete" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Delete
                </button>
                <button @click="closeDeleteDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                    Cancel
                </button>
                </div>
            </div>
            </div>
      </div>
    </div>
  </template>
  
<script>
import { doc, updateDoc, deleteDoc, query, collection, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '@/firebase/init';
import { useToast } from 'vue-toastification';
import loadingGif from '@/assets/images/loading.gif';

  export default {
    data() {
      return {
        password: '',
        errorMessage: '',
        isEmployeeTableAuthenticated: false,
        employees: [],
        loadingGif,
        editEmployee: {
          id: '',
          name: '',
          email: '',
          phone: '',
          department: '',
          position: '',
          employeeNumber: '',
          accessRights: []
        },
        showEditDialog: false,
        showDeleteDialog: false,
        deleteEmployeeId: '',
        loading: false,
        currentPage: 1,
        rowsPerPage: 10,
        searchQuery: '',
        groupByDepartment: '',
        sortBy: 'name',
        departments: [],
        allAccessRights: [
          "Make Announcement",
          "Edit Calendar",
          "Edit PDF",
          "Edit Employee Contacts",
          "HR Templates",
          "Job Descriptions",
          "Edit HR Form",
          "Edit Finance Form",
          "Edit Config"
        ],
        expandedAccess: {}
      };
    },
    computed: {
      allFilteredEmployees() {
        let filtered = this.employees.filter(employee => employee.name !== 'software department admin');

        if (this.searchQuery) {
          filtered = filtered.filter(employee =>
            Object.values(employee).some(value =>
              String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
            )
          );
        }

        if (this.groupByDepartment) {
          filtered = filtered.filter(employee =>
            employee.department === this.groupByDepartment
          );
        }

        if (this.sortBy === 'name') {
          filtered.sort((a, b) => a.name.localeCompare(b.name));
        } else if (this.sortBy === 'employeeNumber') {
          filtered.sort((a, b) => a.employeeNumber.localeCompare(b.employeeNumber));
        }

        return filtered;
      },
      filteredEmployees() {
        const start = (this.currentPage - 1) * this.rowsPerPage;
        const end = start + this.rowsPerPage;
        return this.allFilteredEmployees.slice(start, end);
      },
      totalPages() {
        return Math.ceil(this.allFilteredEmployees.length / this.rowsPerPage);
      },
      paginationInfo() {
        const start = (this.currentPage - 1) * this.rowsPerPage + 1;
        const end = Math.min(start + this.rowsPerPage - 1, this.allFilteredEmployees.length);
        return `${start} - ${end} of ${this.allFilteredEmployees.length}`;
      }
    },
    methods: {
      toggleAccess(employeeId) {
        this.expandedAccess = {
          ...this.expandedAccess,
          [employeeId]: !this.expandedAccess[employeeId]
        };
      },

      async fetchData() {
        const q = query(collection(db, "employees"), orderBy("name", "asc"));
        onSnapshot(q, (querySnapshot) => {
          this.employees = [];
          const departmentsSet = new Set();
          querySnapshot.forEach((doc) => {
            const data = { id: doc.id, ...doc.data() };
            this.employees.push(data);
            departmentsSet.add(data.department);
          });
          this.departments = Array.from(departmentsSet);
        });
      },
      openEditDialog(employee) {
        this.editEmployee = { ...employee };
        this.showEditDialog = true;
      },
      closeEditDialog() {
        this.showEditDialog = false;
      },
      async confirmEdit() {
        this.loading = true;
        this.closeEditDialog();
        try {
          const employeeDoc = doc(db, "employees", this.editEmployee.id);
          await updateDoc(employeeDoc, {
            name: this.editEmployee.name,
            email: this.editEmployee.email,
            phone: this.editEmployee.phone,
            department: this.editEmployee.department,
            position: this.editEmployee.position,
            employeeNumber: this.editEmployee.employeeNumber,
            accessRights: this.editEmployee.accessRights
          });
          this.toast.success('Employee updated successfully!', { timeout: 2000 });
          this.fetchData();
          this.loading = false;
        } catch (error) {
          this.toast.error('Failed to update the employee. Please try again.', { timeout: 2000 });
        }
      },
      openDeleteDialog(id) {
        this.deleteEmployeeId = id;
        this.showDeleteDialog = true;
      },
      closeDeleteDialog() {
        this.showDeleteDialog = false;
      },
      async confirmDelete() {
        this.loading = true;
        this.closeDeleteDialog();
        try {
          const employeeDoc = doc(db, "employees", this.deleteEmployeeId);
          await deleteDoc(employeeDoc);
          this.toast.success('Employee deleted successfully!', { timeout: 2000 });
          this.fetchData();
          this.loading = false;
        } catch (error) {
          this.toast.error('Failed to delete the employee. Please try again.', { timeout: 2000 });
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      }
    },
    watch: {
      rowsPerPage() {
        this.currentPage = 1;
      }
    },
    mounted() {
      this.toast = useToast();
      this.fetchData();
    }
  };
</script>

<style scoped>
input[type="checkbox"]:checked {
background-color: #4299e1;
border-color: #4299e1; 
}

input[type="checkbox"]:checked:after {
border-color: #ffffff;
}

input[type="checkbox"] {
appearance: none;
border: 1px solid #d1d5db;
width: 1.2em;
height: 1.2em;
border-radius: 0.25em;
outline: none;
cursor: pointer;
}

input[type="checkbox"]:checked {
background-color: #4299e1;
border-color: #4299e1;
}

input[type="checkbox"]:checked::after {
content: '✓';
display: block;
color: white;
font-weight: bold;
text-align: center;
line-height: 1.2em;
}
</style>
  
  