<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
        <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
            <ol class="list-reset flex text-gray-700 dark:text-gray-300">
                <li>
                    <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
                </li>
                <li><span class="mx-2">></span></li>
                <li class="text-gray-500 dark:text-gray-400">Configurations</li>
            </ol>
        </nav>

        <div class="w-full 2xl:w-3/4 mx-auto mt-4 p-4">
            <div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                <h2 class="font-bold text-2xl md:text-3xl mb-4">Edit Intranet Configurations</h2>
                <div class="space-y-4">
                    <details class="bg-white dark:bg-gray-800 border-x-2 border-b-2 border-gray-300 dark:border-gray-400 p-4 rounded-lg">
                        <summary class="cursor-pointer text-lg font-bold">Company Name</summary>
                        <div class="mt-2">
                            <label class="block">Name:</label>
                            <input
                                v-model="companyInfo.companyName"
                                readonly
                                type="text"
                                class="mt-1 w-full text-blue-800 dark:text-blue-300 rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                            />
                            <button @click="openEditDialog('companyName')" class="mt-4 bg-green-500 hover:bg-green-300 text-white py-2 px-4 rounded-md">Edit</button>
                        </div>
                    </details>

                    <details class="bg-white dark:bg-gray-800 border-x-2 border-b-2 border-gray-300 dark:border-gray-400 p-4 rounded-lg">
                        <summary class="cursor-pointer text-lg font-bold">Mission</summary>
                        <div class="mt-2">
                            <label class="block">English:</label>
                            <input
                                v-model="companyInfo.mission.english"
                                readonly
                                type="text"
                                class="mt-1 w-full text-blue-800 dark:text-blue-300 rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                            />
                            <label class="block mt-4">Chinese:</label>
                            <input
                                v-model="companyInfo.mission.chinese"
                                readonly
                                type="text"
                                class="mt-1 w-full text-blue-800 dark:text-blue-300 rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                            />
                            <button @click="openEditDialog('mission')" class="mt-4 bg-green-500 hover:bg-green-300 text-white py-2 px-4 rounded-md">Edit</button>
                        </div>
                    </details>

                    <details class="bg-white dark:bg-gray-800 border-x-2 border-b-2 border-gray-300 dark:border-gray-400 p-4 rounded-lg">
                        <summary class="cursor-pointer text-lg font-bold">Vision</summary>
                        <div class="mt-2">
                            <label class="block">English:</label>
                            <input
                                v-model="companyInfo.vision.english"
                                readonly
                                type="text"
                                class="mt-1 w-full text-blue-800 dark:text-blue-300 rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                            />
                            <label class="block mt-4">Chinese:</label>
                            <input
                                v-model="companyInfo.vision.chinese"
                                readonly
                                type="text"
                                class="mt-1 w-full text-blue-800 dark:text-blue-300 rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                            />
                            <button @click="openEditDialog('vision')" class="mt-4 bg-green-500 hover:bg-green-300 text-white py-2 px-4 rounded-md">Edit</button>
                        </div>
                    </details>

                    <details class="bg-white dark:bg-gray-800 border-x-2 border-b-2 border-gray-300 dark:border-gray-400 p-4 rounded-lg">
                        <summary class="cursor-pointer text-lg font-bold">Core Values</summary>
                        <div v-for="(value, key) in companyInfo.coreValues" :key="key" class="mt-2 mb-4">
                            <label class="block">Title:</label>
                            <input
                                v-model="value.title"
                                readonly
                                type="text"
                                class="mt-1 w-full text-blue-800 dark:text-blue-300 rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                            />
                            <label class="block mt-4">{{ value.title }} (English):</label>
                            <textarea
                                v-model="value.description.english"
                                readonly
                                rows="3"
                                class="mt-1 w-full text-blue-800 dark:text-blue-300 rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                            ></textarea>
                            <label class="block mt-4">{{ value.title }} (Chinese):</label>
                            <textarea
                                v-model="value.description.chinese"
                                readonly
                                rows="3"
                                class="mt-1 w-full text-blue-800 dark:text-blue-300 rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                            ></textarea>
                            <button @click="openEditDialog(key)" class="mt-4 bg-green-500 hover:bg-green-300 text-white py-2 px-4 rounded-md">Edit</button>
                        </div>
                    </details>

                </div>
            </div>
        </div>

        <div v-if="showEditDialog" class="fixed z-30 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Edit {{ editSection }}</h3>
                                <div class="mt-2">
                                    <label v-if="editSection === 'companyName'" for="editName" class="block">Company Name:</label>
                                    <input v-if="editSection === 'companyName'"
                                        v-model="editData.companyName"
                                        id="editName"
                                        type="text"
                                        class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                                    />

                                    <label v-else-if="editSection === 'mission' || editSection === 'vision'" for="editEnglish" class="block">English:</label>
                                    <input v-if="editSection === 'mission' || editSection === 'vision'"
                                        v-model="editData.english"
                                        id="editEnglish"
                                        type="text"
                                        class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                                    />
                                    <label v-if="editSection === 'mission' || editSection === 'vision'" for="editChinese" class="block mt-4">Chinese:</label>
                                    <input v-if="editSection === 'mission' || editSection === 'vision'"
                                        v-model="editData.chinese"
                                        id="editChinese"
                                        type="text"
                                        class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                                    />

                                    <label v-else-if="editSection.startsWith('value')" for="editTitle" class="block">Title:</label>
                                    <input v-if="editSection.startsWith('value')"
                                        v-model="editData.title"
                                        id="editTitle"
                                        type="text"
                                        class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                                    />
                                    <label v-if="editSection.startsWith('value')" for="editEnglish" class="block mt-4">English:</label>
                                    <textarea v-if="editSection.startsWith('value')"
                                        v-model="editData.english"
                                        id="editEnglish"
                                        rows="6"
                                        class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                                    ></textarea>
                                    <label v-if="editSection.startsWith('value')" for="editChinese" class="block mt-4">Chinese:</label>
                                    <textarea v-if="editSection.startsWith('value')"
                                        v-model="editData.chinese"
                                        id="editChinese"
                                        rows="6"
                                        class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 dark:border-gray-600"
                                    ></textarea>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button @click="confirmEdit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm">
                            Save changes
                        </button>
                        <button @click="closeEditDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 sm:mt-0 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
                    <img :src="loadingGif" alt="Loading..." class="h-28" />
                    <p class="text-white ml-3">Loading...</p>
        </div>
    </div>
</template>


<script>
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from '@/firebase/init';
import { useToast } from 'vue-toastification';
import loadingGif from '@/assets/images/loading.gif';

export default {
    data() {
        return {
            loadingGif,
            companyInfo: {
                companyName: '',
                mission: {
                    english: '',
                    chinese: ''
                },
                vision: {
                    english: '',
                    chinese: ''
                },
                coreValues: {
                    value1: {
                        title: '',
                        description: {
                            english: '',
                            chinese: ''
                        }
                    },
                    value2: {
                        title: '',
                        description: {
                            english: '',
                            chinese: ''
                        }
                    },
                    value3: {
                        title: '',
                        description: {
                            english: '',
                            chinese: ''
                        }
                    },
                    value4: {
                        title: '',
                        description: {
                            english: '',
                            chinese: ''
                        }
                    }
                },
            },
            showEditDialog: false,
            editSection: '',
            editData: {
                title: '',
                companyName: '',
                english: '',
                chinese: '',
            },
            loading: false,
        };
    },
    methods: {
        async fetchCompanyInfo() {
            this.loading = true;
            try {
                const docRef = doc(db, "configuration", "companyInfo");
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.companyInfo = docSnap.data();
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document: ", error);
            }
            this.loading = false;
        },
        openEditDialog(section) {
            this.editSection = section;
            if (section === 'companyName') {
                this.editData.companyName = this.companyInfo.companyName;
            } else if (section === 'mission' || section === 'vision') {
                this.editData.english = this.companyInfo[section].english;
                this.editData.chinese = this.companyInfo[section].chinese;
            } else if (section.startsWith('value')) {
                this.editData.title = this.companyInfo.coreValues[section].title;
                this.editData.english = this.companyInfo.coreValues[section].description.english;
                this.editData.chinese = this.companyInfo.coreValues[section].description.chinese;
            } 
            this.showEditDialog = true;
        },
        closeEditDialog() {
            this.showEditDialog = false;
        },
        async confirmEdit() {
            this.loading = true;
            this.closeEditDialog();
            try {
                const docRef = doc(db, "configuration", "companyInfo");
                if (this.editSection === 'companyName') {
                    await updateDoc(docRef, {
                        companyName: this.editData.companyName
                    });
                } else if (this.editSection === 'mission' || this.editSection === 'vision') {
                    await updateDoc(docRef, {
                        [`${this.editSection}.english`]: this.editData.english,
                        [`${this.editSection}.chinese`]: this.editData.chinese
                    });
                } else {
                    await updateDoc(docRef, {
                        [`coreValues.${this.editSection}.title`]: this.editData.title,
                        [`coreValues.${this.editSection}.description.english`]: this.editData.english,
                        [`coreValues.${this.editSection}.description.chinese`]: this.editData.chinese
                    });
                }
                this.toast.success('Information updated successfully!', { timeout: 2000 });
                this.fetchCompanyInfo();

            } catch (error) {
                this.toast.error('Failed to update information. Please try again.', { timeout: 2000 });
                console.error("Error updating document: ", error);
            }
            this.loading = false;
        },
    },
    mounted() {
        this.toast = useToast();
        this.fetchCompanyInfo();
    },
};
</script>


<style scoped>
</style>
