<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useRoute } from 'vue-router';
import { getLocalStorage } from '@/utils/localStorageUtil';
import { RouterView } from 'vue-router';
import Sidebar from './components/Sidebar.vue';
import HeaderPage from './components/HeaderElement.vue';

const open = ref(getLocalStorage('sidebarOpen', true));
const contentContainer = ref(null);
const sidebarContainer = ref(null);

const route = useRoute();
const isLoginPage = computed(() => route.name === 'Login');

const updateContentHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  if (contentContainer.value) {
    if (isLoginPage.value) {
      contentContainer.value.style.height = `calc(var(--vh, 1vh) * 100)`;
    } else {
      contentContainer.value.style.height = `calc(var(--vh, 1vh) * 100 - 80px)`;
    }
  }
  if (sidebarContainer.value) {
    sidebarContainer.value.style.height = `calc(var(--vh, 1vh) * 100)`;
  }
};

onMounted(() => {
  updateContentHeight();
  window.addEventListener('resize', updateContentHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateContentHeight);
});
</script>

<template>
  <div class="bg-gray-100 dark:bg-gray-900 transition-colors duration-300 flex overflow-hidden h-screen">
    <div v-if="!isLoginPage" ref="sidebarContainer" class="sidebar-container">
      <Sidebar />
    </div>
    <div class="flex-1 flex flex-col overflow-auto">
      <HeaderPage v-if="!isLoginPage" />
      <div
        ref="contentContainer"
        :class="[
          isLoginPage ? '' : (open ? 'ml-20 md:ml-0' : 'ml-14 md:ml-0'),
          'content-container'
        ]"
        :style="{ height: isLoginPage ? 'calc(var(--vh, 1vh) * 100)' : 'calc(var(--vh, 1vh) * 100 - 80px)' }"
      >
        <RouterView />
      </div>
    </div>
  </div>
</template>

<style scoped>
:root {
--vh: 1vh;
}

.content-container {
  overflow: auto;
}

.sidebar-container {
height: calc(var(--vh, 1vh) * 100);
overflow: auto;
}
</style>
