<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 flex md:h-full flex-col md:p-2">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
              <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li>
          <router-link to="/Announcement" class="text-company-color-1 hover:text-company-color-2">Announcements</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">{{ announcement.title }}</li>
      </ol>
    </nav>
    <div class="flex justify-center items-center p-2">
      <div class="w-full max-w-4xl mx-auto bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden p-6">
        <div class="relative h-96 overflow-hidden image-container cursor-pointer" @click="showImageDialog = true">
          <img
            :src="announcement.imageUrl || defaultImage"
            alt="Announcement Image"
            class="object-cover w-full h-full"
          >
          <div class="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center text-white text-xl font-bold opacity-0 hover:opacity-100 transition-opacity duration-300">
            <div class="rounded-lg p-2 bg-company-color-1">View in Full</div>
          </div>
        </div>
        <div class="p-6">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 text-center">{{ announcement.title }}</h1>
          <p class="text-gray-600 dark:text-gray-400 text-center mb-4">Announcement Date: {{ announcement.date }}</p>
          <p class="text-gray-700 dark:text-gray-300 whitespace-pre-line" v-html="formattedDescription"></p>
        </div>
      </div>
    </div>

    <div v-if="showImageDialog" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div class="bg-white dark:bg-gray-800 p-4 rounded shadow-lg max-w-4xl mx-auto">
        <div class="relative">
          <img :src="announcement.imageUrl || defaultImage" alt="Announcement Image" class="w-full h-auto">
          <button @click="showImageDialog = false" class="absolute top-2 right-2 bg-red-500 rounded-full p-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/firebase/init';
import defaultImage from '@/assets/images/defaultimage.jpg';

export default {
  name: "AnnouncementDisplay",
  data() {
    return {
      announcement: {},
      defaultImage,
      showImageDialog: false,
    };
  },
  computed: {
    formattedDescription() {
      if (this.announcement.description) {
        return this.announcement.description
          .replace(/\n/g, '<br>')
          .replace(/- /g, '&nbsp;&nbsp;&nbsp;&nbsp;- ');
      }
      return '';
    }
  },
  methods: {
    async fetchAnnouncement() {
      const announcementId = this.$route.params.id;
      const announcementDoc = await getDoc(doc(db, "Announcements", announcementId));
      if (announcementDoc.exists()) {
        this.announcement = announcementDoc.data();
      } else {
        console.error('No such document!');
      }
    },
    formatDate(date) {
      try {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      } catch (error) {
        console.error('Invalid date format:', date);
        return 'Invalid Date';
      }
    }
  },
  mounted() {
    this.fetchAnnouncement();
  }
};
</script>

<style scoped>
.image-container {
  position: relative;
  overflow: hidden;
}
.image-container img {
  transition: transform 0.5s ease;
}
.image-container:hover img {
  transform: scale(1.1);
}
</style>
