<template>
  <div :class="{ dark: isDarkMode }">
    <div class="flex-1 flex flex-col sticky" :class="open ? 'ml-20 md:ml-0' : 'ml-14 md:ml-0'">
      <div class="flex items-center justify-between p-3 h-20 bg-white dark:bg-gray-800 shadow-md transition-colors duration-300">
        <div class="flex items-center w-30 md:w-40 text-sm md:text-md justify-start">
          <label class="switch relative inline-block w-10 h-5">
            <input type="checkbox" @change="toggleDarkMode" :checked="isDarkMode" class="opacity-0 w-0 h-0">
            <span class="slider round absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-company-color-2 rounded-full transition-all duration-300 ease-in-out"></span>
            <span
              class="icon absolute top-1/2 left-[2px] w-4 h-4 flex items-center justify-center transform -translate-y-1/2 transition-transform duration-300 ease-in-out bg-gray-800 rounded-full"
              :class="isDarkMode ? 'translate-x-[21px]' : 'translate-x-0'"
            >
              <i :class="isDarkMode ? 'fas fa-sun text-xxs text-gray-300' : 'fas fa-moon text-xxs text-gray-300'"></i>
            </span>
          </label>
        </div>
        <div class="flex items-center w-30 md:w-40 justify-center">
          <img :src="logo" alt="Logo" class="h-14" />
        </div>
        <div class="hidden sm:block w-40">
          <div class="flex items-center justify-center text-gray-800 dark:text-gray-200">
            <div class="text-center">
              <div class="text-lg">{{ currentDay }}</div>
              <div class="text-md">{{ currentDate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getLocalStorage, setLocalStorage } from '@/utils/localStorageUtil';
import logo from '@/assets/images/logo.png';

export default {
  setup() {
    const open = ref(getLocalStorage('sidebarOpen', true));
    const isDarkMode = ref(getLocalStorage('darkMode', false));

    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value;
      setLocalStorage('darkMode', isDarkMode.value);
      document.documentElement.classList.toggle('dark', isDarkMode.value);
    };

    const currentDay = ref('');
    const currentDate = ref('');

    const updateDateTime = () => {
      const date = new Date();
      const optionsDay = { weekday: 'long' };
      const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
      currentDay.value = date.toLocaleDateString('en-US', optionsDay);
      currentDate.value = date.toLocaleDateString('en-US', optionsDate);
    };

    onMounted(() => {
      document.documentElement.classList.toggle('dark', isDarkMode.value);
      updateDateTime();
      const interval = setInterval(updateDateTime, 1000);
      return () => clearInterval(interval);
    });

    return {
      open,
      isDarkMode,
      toggleDarkMode,
      currentDay,
      currentDate,
      logo
    };
  }
};
</script>

<style scoped>

</style>