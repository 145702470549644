// src/utils/localStorageUtil.js

const TIMEOUT_HOURS = 10;
const TIMEOUT_MS = TIMEOUT_HOURS * 60 * 60 * 1000;

export function getLocalStorage(key, defaultValue) {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorageWithTimeout(key, defaultValue) {
  const storedValue = localStorage.getItem(key);
  if (storedValue) {
    const { value, timestamp } = JSON.parse(storedValue);
    const now = new Date().getTime();
    if (now - timestamp < TIMEOUT_MS) {
      return value;
    } else {
      localStorage.removeItem(key);
      return defaultValue;
    }
  }
  return defaultValue;
}

export function setLocalStorageWithTimeout(key, value) {
  const now = new Date().getTime();
  const item = {
    value: value,
    timestamp: now,
  };
  localStorage.setItem(key, JSON.stringify(item));
}
