<template>
  <div class="pdf-viewer relative text-center h-full">
    <iframe :src="pdfSrc" allowfullscreen="true" class="pdf-embed h-full w-full max-w-4xl" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db } from '@/firebase/init';
import { doc, getDoc } from 'firebase/firestore';

export default {
  name: 'PDFViewer',
  props: {
    documentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const pdfSrc = ref('');

    const loadDocument = async (documentId) => {
      try {
        const docRef = doc(db, 'PDFs_File_ID', documentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const driveFileId = docSnap.data().file_id;
          pdfSrc.value = `https://drive.google.com/file/d/${driveFileId}/preview`;
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error loading PDF document:', error.message);
      }
    };

    onMounted(() => {
      loadDocument(props.documentId);
    });

    return {
      pdfSrc,
    };
  },
};
</script>

<style scoped>
.pdf-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 108px);
}
</style>
