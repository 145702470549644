<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
        <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
            <ol class="list-reset flex text-gray-700 dark:text-gray-300">
                <li>
                    <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
                </li>
                <li><span class="mx-2">></span></li>
                <li class="text-gray-500 dark:text-gray-400">Change Password</li>
            </ol>
        </nav>
        <div class="flex-grow flex justify-center items-center">
            <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-md w-full">
                <h2 class="font-bold text-2xl md:text-3xl mb-6 text-center">Change Password</h2>
    
                <!-- Verify Current Password Form -->
                <form @submit.prevent="verifyCurrentPassword" v-if="!isVerified">
                    <p class="mb-4 text-gray-700 dark:text-gray-300">
                        Please enter your current password
                    </p>

                    <!-- Current Password -->
                    <div class="relative mb-4">
                        <label for="currentPassword" class="block text-gray-700 dark:text-gray-300 mb-2">Current Password</label>   
                        <div class="relative mb-4">
                            <input
                                :type="showCurrentPassword ? 'text' : 'password'"
                                v-model="currentPassword"
                                id="currentPassword"
                                required
                                placeholder="Enter your current password"
                                class="w-full rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2"
                            />
                            <i
                            :class="[showCurrentPassword ? 'fa-eye-slash' : 'fa-eye', 'fa', 'absolute', 'right-3', 'top-1/2', 'transform', '-translate-y-1/2', 'text-gray-500', 'cursor-pointer']"
                            @click="toggleCurrentPasswordVisibility"
                            ></i>       
                        </div>
                    </div>
                    <div class="text-red-500 mb-4" v-if="errorMessage">{{ errorMessage }}</div>
                
                    <button type="submit" class="w-full bg-company-color-1 text-white py-2 px-4 rounded hover:bg-company-color-2 focus:outline-none focus:shadow-outline">
                        Verify
                    </button>
                </form>
    
                <form @submit.prevent="changePassword" v-if="isVerified">
                    <p class="mb-4 text-gray-700 dark:text-gray-300">
                        Please key in and confirm your new password
                    </p>
                    
                    <div class="relative mb-4">
                        <label for="newPassword" class="block text-gray-700 dark:text-gray-300 mb-2">New Password</label>
                        <div class="relative mb-4">
                            <input
                            :type="showNewPassword ? 'text' : 'password'"
                            v-model="newPassword"
                            id="newPassword"
                            required
                            placeholder="Enter your new password"
                            class="w-full rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2"
                            />
                            <i
                            :class="[showNewPassword ? 'fa-eye-slash' : 'fa-eye', 'fa', 'absolute', 'right-3', 'top-1/2', 'transform', '-translate-y-1/2', 'text-gray-500', 'cursor-pointer']"
                            @click="toggleNewPasswordVisibility"
                            ></i>
                        </div>
                    </div>
            
                    <div class="relative mb-4">
                        <label for="confirmPassword" class="block text-gray-700 dark:text-gray-300 mb-2">Confirm New Password</label>
                        <div class="relative mb-4">
                            <input
                            :type="showConfirmPassword ? 'text' : 'password'"
                            v-model="confirmPassword"
                            id="confirmPassword"
                            required
                            placeholder="Confirm your new password"
                            class="w-full rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2"
                            />
                            <i
                            :class="[showConfirmPassword ? 'fa-eye-slash' : 'fa-eye', 'fa', 'absolute', 'right-3', 'top-1/2', 'transform', '-translate-y-1/2', 'text-gray-500', 'cursor-pointer']"
                            @click="toggleConfirmPasswordVisibility"
                            ></i>
                        </div>
                    </div>
                
                    <div class="text-red-500 mb-4" v-if="errorMessage">{{ errorMessage }}</div>
                
                    <button type="submit" class="w-full bg-company-color-1 text-white py-2 px-4 rounded hover:bg-company-color-2 focus:outline-none focus:shadow-outline">
                        Change Password
                    </button>
                </form>
            
                <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
                    <img :src="loadingGif" alt="Loading..." class="h-28" />
                    <p class="text-white ml-3">Loading...</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { query, where, collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "@/firebase/init";
import { getLocalStorage } from "@/utils/localStorageUtil";
import loadingGif from "@/assets/images/loading.gif";
import { useToast } from "vue-toastification";

export default {
data() {
    return {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    isVerified: false,
    loading: false,
    errorMessage: "",
    loadingGif,
    userDocumentId: null,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    };
},
methods: {
    toggleCurrentPasswordVisibility() {
    this.showCurrentPassword = !this.showCurrentPassword;
    },
    toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
    },
    toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
    },
    async verifyCurrentPassword() {
    const employeeEmailObject = getLocalStorage("loggedInEmployeeEmail", {});
    const employeeEmail = employeeEmailObject.value || employeeEmailObject;

    this.loading = true;
    const toast = useToast();

    try {
        const q = query(collection(db, "employees"), where("email", "==", employeeEmail));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const employeeData = doc.data();
            const storedPassword = employeeData.password;

            if (storedPassword === this.currentPassword) {
            this.isVerified = true;
            this.userDocumentId = doc.id;
            this.errorMessage = "";
            } else {
            this.errorMessage = "Current password is incorrect.";
            }
        });
        } else {
        this.errorMessage = "User not found.";
        }
    } catch (error) {
        console.error("Error verifying current password:", error);
        toast.error("Failed to verify password. Please try again.");
    } finally {
        this.loading = false;
    }
    },

    async changePassword() {
    if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = "New passwords do not match.";
        return;
    }

    this.loading = true;
    const toast = useToast();

    try {
        const employeeDocRef = doc(db, "employees", this.userDocumentId);
        await updateDoc(employeeDocRef, { password: this.newPassword });

        toast.success("Password changed successfully!");
        this.resetForm();
        this.$router.push('/Dashboard');
    } catch (error) {
        console.error("Error changing password:", error);
        toast.error("Failed to change password. Please try again.");
    } finally {
        this.loading = false;
    }
    },

    resetForm() {
        this.currentPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";
        this.errorMessage = "";
        this.isVerified = false;
        this.userDocumentId = null;
        this.showCurrentPassword = false;
        this.showNewPassword = false;
        this.showConfirmPassword = false;
    },
},
};
</script>

<style scoped>
</style>
