<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full">
        <nav class="px-4 pt-2 mb-1 mx-4 text-xs lg:text-md h-6">
            <ol class="list-reset flex text-gray-700 dark:text-gray-300">
                <li>
                    <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>

                </li>
                <li>
                    <span class="mx-2">></span>
                </li>
                <li class="text-gray-500 dark:text-gray-400">Founder</li>
            </ol>
        </nav>
        <div class="flex flex-wrap">
            <div class="mx-auto max-w-4xl h-full flex flex-col justify-center relative">
                <button @click="toggleLanguage" class="absolute left-0 top-1/2 transform -translate-y-1/2 text-4xl text-company-color-1 hover:text-company-color-2 focus:outline-none">
                    &lt;
                </button>
                <div class="text-2xl md:text-4xl text-company-color-1 text-center mb-8 md:mb-24 font-semibold mt-4">
                    Our Founder
                </div>
                <div v-if="isEnglish" class="text-justify h-full p-2 text-sm grid grid-cols-1 lg:grid-cols-2 english">
                    <div class="flex flex-col items-center justify-end lg:mr-4">
                        <img :src="FounderIMG" alt="Founder" class="h-72 md:h-96 object-cover mb-4">
                        <div class="flex flex-row items-center justify-center w-full mb-4">
                            <img :src="logo2" alt="Founder" class="h-10 object-cover mr-2">
                            <div class="font-bold text-company-color-1">
                                <p>MR. KENNY CHUA</p>
                                <p>Founder and CEO</p>
                            </div>
                        </div>
                        <div class="p-4 lg:p-2">
                            <p class="mb-4">KENNY is a seasoned entrepreneur with more than 19 years of experience in diverse industries. Throughout his career, he has honed his expertise in Online and digital marketing, establishing himself as a driving force in the field. His passion has led him to found multiple successful ventures.</p>
                            <p>
                                KENNY's entrepreneurial journey began when he spearheaded the transformation of traditional brick-and-mortar businesses into thriving online enterprises. By leveraging his technical prowess and strategic vision, he developed World-Market, an e-commerce portal that revolutionized the way businesses connect with customers that not only brought success to his brother
                                <span class="inline lg:hidden">and local store owners in the UK but also served as a steppingstone for his future endeavors.</span>
                            </p>
                        </div>
                    </div>
                    <div class="flex flex-col items-center justify-end lg:mr-4">
                        <div class="p-4 lg:p-2">
                            <p class="mb-4 lg:block">and local store owners in the UK but also served as a steppingstone for his future endeavors.</p>
                            <p class="mb-4">Building on this initial triumph, he co-founded YeahHost, providing web hosting solutions upon his return from the UK. Through the unwavering dedication and confidence, he instilled in his clients, KENNY secured a substantial investment of RM200,000, propelling him to strike gold with his first company, Game Pro. He then successfully acquired a media company, which was later named Vilor Media, driving online traffic and digital marketing. This early triumph provided the foundation for his subsequent forays into entrepreneurship and angel investing.</p>
                            <p class="mb-4">In 2016, KENNY founded Vilor Berhad, believing in the transformative power of nurturing potential, he dedicates his time and resources to incubating and transforming ambitious ideas into profitable companies. Motivated by an authentic commitment to empower aspiring startups since he has been one of those himself, he has incubated and mentored over 1,000 companies and young talents who dare to dream big. His guiding principle is that "God will give you something, never to others, so, everything is the best arrangement", reflecting his optimistic outlook on life and business. Demonstrating remarkable acumen and strategic foresight, Vilor swiftly acquired shares in 23 companies within a span of 10 months, enhancing its competitiveness and solidifying its position in the market, which also helped him earn the directorship in a public listed company. </p>
                            <p>As the visionary behind Vilor, KENNY embodies the resilience and ambition that define modern entrepreneurship. His journey is not always smooth, marked by numerous lessons learned and achievements, but his unwavering commitment to nurturing budding startups serves as an inspiration to aspiring business leaders and a testament to his enduring impact on the angel investing landscape.</p>
                        </div>
                    </div>
                </div>
                <div v-else class="text-justify h-full p-2 text-sm grid grid-cols-1 lg:grid-cols-2 chinese">
                    <div class="flex flex-col items-center justify-end lg:mr-4">
                        <img :src="FounderIMG" alt="Founder" class="h-72 md:h-96 object-cover mb-4">
                        <div class="flex flex-row items-center justify-center w-full mb-4">
                            <img :src="logo2" alt="Founder" class="h-10 object-cover mr-2">
                            <div class="font-bold text-company-color-1">
                                <p>蔡一致 (KENNY CHUA)</p>
                                <p>创始人兼首席执行官</p>
                            </div>
                        </div>
                        <div class="p-4 lg:p-2">
                            <p class="mb-0">KENNY是一位经验丰富的企业家，在不同行业拥有超过18年的经验。他在职涯中锐意进取，在线和数字营销方面磨练了自己的专业知识，希望成为该领域的一股动力。他的热情也驱使他后期创办了多个成功的企业。</p>
                        </div>
                    </div>
                    <div class="flex flex-col items-center justify-end lg:mr-4">
                        <div class="p-4 lg:p-2">
                            <p class="mb-4">KENNY的创业之路始于他领导传统实体店铺向蓬勃发展的在线企业转型。凭借其技术实力和战略眼光，他开发了World-Market，一家电子商务网站推动革新与客户的沟通方式。该平台不仅为他的兄长和英国本土店主带来了可观的收益，也成为他未来事业的垫脚石。</p>
                            <p class="mb-4">在这个初步胜利的基础上，他回国后共同创立了YeahHost，一个提供网络托管解决方案的公司。通过他对客户的坚定奉献和信心，KENNY成功获得了20万令吉的大额投资，推动他的首个公司Game Pro并赚取了第一桶金。随后，他成功收购了一家媒体公司，后来更名为Vilor Media，在推动媒体流量和数字营销方面取得了显著成就。这些早期的经验为他后续的创业和天使投资之路奠定了基础。</p>
                            <p class="mb-4">在2016年，KENNY创办了Vilor Berhad。深信培育有潜力和革新人才的力量，他将自己的时间和资源投入到孵化和转化创意，使其成为盈利的企业。他秉持着真诚的承诺，致力于有抱负的初创企业，因为他曾经是其中之一，他孵化和指导了超过1,000家企业和勇敢追梦的年轻人。他的指导原则是“一切都是上天最好的安排”，反映了他对生活和商业的乐观态度。Vilor展现了出色的洞察力和战略前瞻性，在10个月内迅速收购了23家公司的股份，提升了竞争力并巩固了在市场中的地位，他也是上市公司的董事，这是对他的一个肯定。</p>
                            <p>作为Vilor背后的引航者，KENNY体现了现代创业精神的韧性和雄心。他的创业之路并非他的创业之路并非一帆风顺，有许多学习也有不少成就，但他对培育初创企业的坚定承诺，这对于有志于成为企业佼佼者是一种鼓舞，也证明了他在天使投资领域的持久影响力。</p>  
                        </div>
                    </div>
                </div>
                <button @click="toggleLanguage" class="absolute right-0 top-1/2 transform -translate-y-1/2 text-4xl text-company-color-1 hover:text-company-color-2 focus:outline-none">
                    &gt;
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import FounderIMG from '@/assets/images/Founder/Founder.png';
import logo2 from '@/assets/images/logo2.png';

export default {
    name: "Founder",
    data() {
        return {
            FounderIMG,
            logo2,
            isEnglish: true,
        };
    },
    methods: {
        toggleLanguage() {
            this.isEnglish = !this.isEnglish;
        }
    },
};
</script>

<style scoped>
</style>
