<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 flex flex-col md:p-2 h-full">
      <nav class="px-4 pt-2 mb-1 mx-4 text-xs lg:text-md h-6">
          <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
              <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>

          </li>
          <li>
              <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Core Values</li>
          </ol>
      </nav>
      <div class="justify-start flex h-full flex-col">
        <div class="flex flex-col text-2xl text-center text-company-color-1 mt-8 font-bold">
            <h4>Core Values</h4>
            <h4>核心价值观</h4>
        </div>
        <div class="max-w-3xl mx-auto mt-10 p-4">
            <div class="mb-10">
                <h4 class="text-lg font-bold">What are Core Values?</h4>
                <h4 class="text-lg font-bold">什么是核心价值观？</h4>
            </div>
            <p class="text-base text-justify mb-6"><span class="text-company-color-1 ">Core values</span> are suitable for individuals, companies, organizations, and even countries. A strong <span class="text-company-color-1">company is founded on the core values upheld by its leaders.</span> In essence, they are the <span class="text-company-color-1">“true north”</span> that helps an organization navigating through prosperous times as well as uncertain periods. These values lead the company to overcome challenges, keep the business prosperous and achieve its goals. More often than not, the company beliefs are also embodied in its culture.</p>
            <p class="text-base text-justify">核心价值观适用于个人、公司、组织，甚至国家。一家强大的公司是建立在其领导者所坚守的核心价值观之上。本质上，它们是帮助组织在繁荣时期和不确定时期中导航的“北极星”。这些价值观引导公司克服挑战，保持业务繁荣并实现其目标。一般公司的信念也体现在其文化中。</p>

            <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center md:justify-between my-8 quote">
                <div class="flex-shrink-0 mb-4 md:mb-0 md:mr-4">
                    <img :src="CoreValuesQuote1" alt="CoreValuesQuote1" class="w-40 h-40 md:w-50 md:h-50 object-cover">
                </div>
                <div class="text-center md:text-right">
                    <p>"Your personal core values define who you are, and a company's core values ultimately define the company's character and brand. For individuals, character is destiny. For organizations, culture is destiny"</p>
                    <p class="mt-6"><i>~ Tony Hsieh</i></p>
                </div>
            </div>

            <div class="mb-10">
                <h4 class="text-lg font-bold">Why do Core Values matter?</h4>
                <h4 class="text-lg font-bold">为什么核心价值观很重要？</h4>
            </div>
            <p class="text-base text-justify mb-6">
                As the guiding light that bonds a team with a shared sense of purpose for achieving common goals, <span class="text-company-color-1 font-semibold">core values</span> set the identity of an organization that all employees should understand, work towards and live by. They are the <span class="text-company-color-1 font-semibold">yardstick</span> by which employees determine the best course of action.
                If their decision does not align with the company’s values, it is a red flag that signals they should reconsider their response. Such values alignment is of growing importance to jobseekers, therefore <span class="text-company-color-1 font-semibold">attracting likeminded candidates who hold the same principles as the organization and retaining them</span> for longer because they already share the core beliefs and work well within the existing team.
                Our culture and values are <span class="text-company-color-1 font-semibold">differentiators that help us stand apart from other organizations</span> operating in the same field. In a competitive environment, values that resonate with our customers will help us attract and retain them.
            </p>
            <p class="text-base text-justify mb-6">
                核心价值观是凝聚团队的明灯，具有共同的使命感，以实现共同的目标。核心价值观确立了组织的身份，所有员工都应该理解、努力追求并践行。它们是员工判断最佳行动方向的尺度。如果他们的决策与公司的价值观不一致，那就是一个警示信号，表明他们应该重新考虑自己的回应。这种价值观的一致性对求职者来说越来越重要，因此能够吸引志同道合、与组织拥有相同原则的伙伴，并因为他们已经有一致的核心信念并在现有团队中牢牢的留住了他们的心。
                公司的文化和价值观是我们的独特之处，帮助我们从同一领域的其他组织中脱颖而出。在竞争环境中，与我们客户产生共鸣的价值观将帮助我们吸引并留住他们。
            </p>

            <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center md:justify-between my-8 quote">
                <div class="flex-shrink-0 mb-4 md:mb-0 md:mr-4">
                    <img :src="CoreValuesQuote2" alt="Vision" class="w-40 h-40 md:w-50 md:h-50 object-cover">
                </div>
                <div class="text-center md:text-right">
                    <p>"Great companies are built on great core values. They provide clarity, shape the culture, and define what the company stands for."</p>
                    <p class="mt-6"><i>~ Simon Sinek</i></p>
                </div>
            </div>

            <div class="mb-10">
                <h4 class="text-lg font-bold">What We Believe</h4>
                <h4 class="text-lg font-bold">我们的信念</h4>
            </div>
            <div class="bg-white dark:bg-gray-800 p-4 my-8">
                <div class="grid grid-col-1 md:grid-cols-2 gap-4">
                    <div class="group">
                        <img :src="value1" alt="Selflessness" class="object-cover w-full h-52">
                        <p class="text-center mt-2 text-lg font-semibold">{{ coreValues[0]?.title }}</p>
                    </div>
                    <div class="group">
                        <img :src="value2" alt="Focus" class="object-cover w-full h-52">
                        <p class="text-center mt-2 text-lg font-semibold">{{ coreValues[1]?.title }}</p>
                    </div>
                    <div class="group">
                        <img :src="value3" alt="Integrity" class="object-cover w-full h-52">
                        <p class="text-center mt-2 text-lg font-semibold">{{ coreValues[2]?.title }}</p>
                    </div>
                    <div class="group">
                        <img :src="value4" alt="Accountability" class="object-cover w-full h-52">
                        <p class="text-center mt-2 text-lg font-semibold">{{ coreValues[3]?.title }}</p>
                    </div>
                </div>
            </div>

            <div v-for="(value, index) in coreValues" :key="index" class="mb-10">
                <h4 class="text-lg font-bold mb-4 text-company-color-1">{{ value?.title }}</h4>
                <p class="text-base text-justify mb-6">{{ value?.descriptionEnglish }}</p>
                <p class="text-base text-justify mb-6">{{ value?.descriptionChinese }}</p>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/firebase/init';
import CoreValuesQuote1 from '@/assets/images/CoreValues/CoreValuesQuote1.jpg';
import CoreValuesQuote2 from '@/assets/images/CoreValues/CoreValuesQuote2.jpg';
import value1 from '@/assets/images/CoreValues/value1.jpg';
import value2 from '@/assets/images/CoreValues/value2.jpg';
import value3 from '@/assets/images/CoreValues/value3.jpg';
import value4 from '@/assets/images/CoreValues/value4.jpg';

export default {
  name: "CoreValues",
  data() {
    return {
      CoreValuesQuote1,
      CoreValuesQuote2,
      value1,
      value2,
      value3,
      value4,
      coreValues: [],
    };
  },
  async mounted() {
    try {
      const docRef = doc(db, "configuration", "companyInfo");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.coreValues) {
          this.coreValues = [
            {
              title: data.coreValues.value1?.title || "Default Title 1",
              descriptionEnglish: data.coreValues.value1?.description.english || "Default Description English 1",
              descriptionChinese: data.coreValues.value1?.description.chinese || "Default Description Chinese 1",
            },
            {
              title: data.coreValues.value2?.title || "Default Title 2",
              descriptionEnglish: data.coreValues.value2?.description.english || "Default Description English 2",
              descriptionChinese: data.coreValues.value2?.description.chinese || "Default Description Chinese 2",
            },
            {
              title: data.coreValues.value3?.title || "Default Title 3",
              descriptionEnglish: data.coreValues.value3?.description.english || "Default Description English 3",
              descriptionChinese: data.coreValues.value3?.description.chinese || "Default Description Chinese 3",
            },
            {
              title: data.coreValues.value4?.title || "Default Title 4",
              descriptionEnglish: data.coreValues.value4?.description.english || "Default Description English 4",
              descriptionChinese: data.coreValues.value4?.description.chinese || "Default Description Chinese 4",
            }
          ];
        } else {
          console.log("No coreValues found in the document.");
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
    }
  }
};
</script>

<style scoped>
.quote p {
    font-family: 'Times New Roman', Times, serif;
}
</style>
