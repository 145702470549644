<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full">
    <div>
      <h3 class="text-4xl font-bold text-center text-company-color-1 mt-4 mb-8">Dashboard</h3>
      <div class="container mx-auto px-4 sm:px-6 lg:px-8 mt-12">
        <div class="flex flex-col xl:flex-row xl:space-x-4">
          <div class="w-full xl:w-1/3 bg-white dark:bg-gray-800 rounded-xl shadow-lg mb-4 flex animate-slideInLeft h-80 flex-col items-center justify-center">
            <DigitalClock />
            <div class="text-center mt-4">
              <div class="text-lg">{{ currentDay }}</div>
              <div class="text-md">{{ currentDate }}</div>
            </div>
          </div>

          <div class="w-full bg-white dark:bg-gray-800 rounded-xl shadow-lg mb-4 flex flex-col xl:flex-row animate-slideInRight xl:h-80">
            <div class="flex flex-col lg:flex-row w-full p-2">
              <div class="flex flex-col bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md xl:w-1/4">
                <h2 class="font-bold text-xl lg:text-2xl mb-3">Legend</h2>
                <div class="flex items-center mb-2 text-xs 2xl:text-sm">
                  <div class="w-4 h-4 bg-purple-500 mr-2"></div>
                  <span class="w-4/5">Payroll Payment</span>
                </div>
                <div class="flex items-center mb-2 text-xs 2xl:text-sm">
                  <div class="w-4 h-4 bg-cyan-500 mr-2"></div>
                  <span class="w-4/5">Payroll cut-off</span>
                </div>
                <div class="flex items-center mb-2 text-xs 2xl:text-sm">
                  <div class="w-4 h-4 bg-blue-500 mr-2"></div>
                  <span class="w-4/5">Claim cut-off</span>
                </div>
                <div class="flex items-center mb-2 text-xs 2xl:text-sm">
                  <div class="w-4 h-4 bg-red-500 mr-2"></div>
                  <span class="w-4/5">Month End Closing Week</span>
                </div>
                <div class="flex items-center text-xs 2xl:text-sm">
                  <div class="w-4 h-4 bg-orange-500 mr-2"></div>
                  <span class="w-4/5">Weekends of Month End Closing Week</span>
                </div>
              </div>

              <div class="flex flex-col xl:flex-row w-full xl:w-1/2 justify-center my-4 lg:my-0 mx-0 lg:mx-4">
                <div class="w-full bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md overflow-auto h-full">
                  <h2 class="font-bold text-xl lg:text-2xl text-center mb-2">{{ currentMonth.name }} {{ currentMonth.year }}</h2>
                  <ul class="flex justify-between text-sm xl:text-lg text-white bg-company-color-1 mb-2 p-2 px-3 rounded-tl-lg rounded-tr-lg">
                    <li class="w-1/7 text-center">Su</li>
                    <li class="w-1/7 text-center">Mo</li>
                    <li class="w-1/7 text-center">Tu</li>
                    <li class="w-1/7 text-center">We</li>
                    <li class="w-1/7 text-center">Th</li>
                    <li class="w-1/7 text-center">Fr</li>
                    <li class="w-1/7 text-center">Sa</li>
                  </ul>
                  <div class="p-2">
                      <div v-for="week in currentMonth.weeks" :key="week" class="flex justify-between mb-0.5">
                          <div v-for="day in week" :key="day.date" 
                              :class="{
                              'text-gray-300 dark:text-gray-500': day.isPrevMonth || day.isNextMonth,
                              'bg-purple-500 text-white': day.isPayrollPayment && !day.isPrevMonth && !day.isNextMonth,
                              'bg-cyan-500 text-white': day.isPayrollCutoff && !day.isPrevMonth && !day.isNextMonth,
                              'bg-blue-500 text-white': day.isClaimCutoff && !day.isPrevMonth && !day.isNextMonth,
                              'bg-red-500 text-white': day.isMonthEndClosingWeek && !day.isPrevMonth && !day.isNextMonth,
                              'bg-orange-500 text-white': day.isMonthEndClosingWeekend && !day.isPrevMonth && !day.isNextMonth
                              }"
                              class="flex items-center justify-center h-6 w-6 rounded-full text-sm xl:text-lg">
                              {{ day.date }}
                          </div>
                      </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col xl:w-1/4">
                <div v-if="nextPublicHoliday" class="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md text-center h-full">
                  <h2 class="font-bold text-xl lg:text-2xl mb-8">Next Public Holiday:</h2>
                  <h3 class="font-bold text-lg lg:text-xl text-company-color-1 mb-1">{{ nextPublicHoliday.holiday }}</h3>
                  <p class="text-sm lg:text-md">{{ nextPublicHoliday.date }} ({{ nextPublicHoliday.day }})</p>
                </div>
                <div v-else class="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md text-center h-full">
                  <h2 class="font-bold text-xl lg:text-2xl mb-8 ">Next Public Holiday:</h2>
                  <p class="text-sm lg:text-md">No upcoming holidays.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col xl:flex-row xl:space-x-4">
          <div class="w-full xl:w-1/2 bg-white dark:bg-gray-800 rounded-xl shadow-lg mb-4 animate-slideInLeft xl:h-80">
            <router-link 
              :to="newestAnnouncement ? `/AnnouncementDisplay/${newestAnnouncement.id}` : '#'"
              class="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden relative group h-full flex flex-col"
            >
              <div class="relative h-48 overflow-hidden">
                <img :src="newestAnnouncement?.imageUrl || defaultImage" alt="Announcement Image" class="object-cover w-full h-full group-hover:blur-md transition duration-300">
              </div>
              <div class="p-4 flex flex-col flex-grow justify-between group-hover:blur-md transition duration-300">
                <div>
                  <h3 class="font-bold text-xl md:text-2xl mb-2">
                    {{ newestAnnouncement ? newestAnnouncement.title : 'No New Announcements' }}
                  </h3>
                </div>
                <div v-if="newestAnnouncement" class="mt-auto">
                  <p class="text-sm md:text-md text-gray-600 dark:text-gray-400">Announcement Date: {{ newestAnnouncement.date }}</p>
                  <p class="text-sm md:text-md mt-2 text-gray-700 dark:text-gray-300">
                    {{ truncatedDescription(newestAnnouncement.description) }}
                    <span v-if="isDescriptionTruncated(newestAnnouncement.description)">...</span>
                  </p>
                </div>
                <div v-else class="mt-auto">
                  <p class="text-sm md:text-md text-gray-600 dark:text-gray-400">No announcements available at this time.</p>
                </div>
              </div>
              <div v-if="newestAnnouncement" class="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center text-white text-xl font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div class="rounded-lg p-2 bg-company-color-1">View</div>
              </div>
            </router-link>
          </div>

          <router-link to="/HRForms" class="w-full xl:w-1/4 bg-white dark:bg-gray-800 rounded-xl shadow-lg mb-4 animate-slideInLeft h-80 hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-center items-center group">
            <h3 class="text-xl lg:text-2xl font-bold mb-2">Quick Link to:</h3>
            <div class="relative w-full h-48 mb-2 overflow-hidden">
              <img src="@/assets/images/Dashboard/HRForms.jpg" alt="HR Forms" class="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-110">
            </div>
            <p class="text-md lg:text-xl">HR Forms</p>
          </router-link>

          <router-link to="/FinanceForms" class="w-full xl:w-1/4 bg-white dark:bg-gray-800 rounded-xl shadow-lg mb-4 animate-slideInLeft h-80 hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-center items-center group">
            <h3 class="text-xl lg:text-2xl font-bold mb-2">Quick Link to:</h3>
            <div class="relative w-full h-48 mb-2 overflow-hidden">
              <img src="@/assets/images/Dashboard/FinanceForms.jpg" alt="Finance Forms" class="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-110">
            </div>
            <p class="text-md lg:text-xl">Finance Forms</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, query, orderBy, limit, onSnapshot, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from '@/firebase/init';
import defaultImage from '@/assets/images/defaultimage.jpg';  
import DigitalClock from '@/components/DigitalClock.vue';

export default {
  name: "Introduction",
  components: {
      DigitalClock
  },
  data() {
      return {
          companyName: '',  // Initialize as empty string
          newestAnnouncement: null,
          defaultImage,
          currentDay: '',
          currentDate: '',
          fiscalYear: null,
          months: [],
          holidays: [],
          nextPublicHoliday: null,
          currentMonth: {}
      };
  },
  methods: {
      async fetchCompanyName() {
          try {
              const docRef = doc(db, 'configuration', 'companyInfo');
              const docSnap = await getDoc(docRef);
              if (docSnap.exists()) {
                  this.companyName = docSnap.data().companyName || 'Company Name';
              } else {
                  console.error('No companyInfo document found!');
                  this.companyName = 'Company Name';
              }
          } catch (error) {
              console.error('Error fetching company name:', error);
              this.companyName = 'Company Name';
          }
      },
      fetchNewestAnnouncement() {
          const q = query(collection(db, "Announcements"), orderBy("date", "desc"), limit(1));
          onSnapshot(q, (querySnapshot) => {
              this.newestAnnouncement = null;
              querySnapshot.forEach((doc) => {
                  const data = doc.data();
                  if (data.title && data.date && data.description) {
                      this.newestAnnouncement = { id: doc.id, ...data };
                  } else {
                      console.error('Missing data fields in document:', doc.id);
                  }
              });
          });
      },
      truncatedDescription(description) {
          const maxLength = 170;
          return description.length > maxLength ? description.slice(0, maxLength) : description;
      },
      isDescriptionTruncated(description) {
          const maxLength = 170;
          return description.length > maxLength;
      },
      updateDateTime() {
          const date = new Date();
          const optionsDay = { weekday: 'long' };
          const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
          this.currentDay = date.toLocaleDateString('en-US', optionsDay);
          this.currentDate = date.toLocaleDateString('en-US', optionsDate);
      },
      getFiscalYear() {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          return currentDate.getMonth() >= 1 ? currentYear : currentYear - 1;
      },
      generateFiscalCalendar() {
          const fiscalYear = this.fiscalYear;
          const months = [];
          const monthNames = [
              'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January'
          ];
          const fiscalStartMonth = 1; // February
          for (let i = 0; i < 12; i++) {
              const monthIndex = (fiscalStartMonth + i) % 12;
              const year = monthIndex < fiscalStartMonth ? fiscalYear + 1 : fiscalYear;
              const name = monthNames[i];
              const weeks = this.generateWeeks(year, monthIndex);
              months.push({ name, year, index: monthIndex, weeks });
          }
          return months;
      },
      generateWeeks(year, month) {
          const weeks = [];
          const date = new Date(year, month, 1);
          const firstDay = date.getDay();
          const lastDate = new Date(year, month + 1, 0).getDate();
          const prevMonthLastDate = new Date(year, month, 0).getDate();
          let week = [];
          
          // Previous month's days
          for (let i = firstDay - 1; i >= 0; i--) {
              week.push({ date: prevMonthLastDate - i, isPrevMonth: true });
          }
          // Current month's days
          for (let day = 1; day <= lastDate; day++) {
              week.push({ date: day, isPrevMonth: false, isNextMonth: false });
              if (week.length === 7) {
                  weeks.push(week);
                  week = [];
              }
          }
          // Next month's days
          const nextMonthDays = 7 - week.length;
          for (let i = 1; i <= nextMonthDays; i++) {
              week.push({ date: i, isNextMonth: true });
          }
          weeks.push(week);
          return weeks;
      },
      async fetchHolidays() {
          const holidaysCollection = collection(db, 'holidays');
          const holidaysSnapshot = await getDocs(holidaysCollection);
          this.holidays = holidaysSnapshot.docs.map(doc => doc.data());
          this.applyFiscalInfoToCalendar();
          this.calculateNextPublicHoliday();
      },
      async fetchFiscalInfo() {
          const fiscalDocRef = doc(db, 'fiscalInfo', 'gPjFt96yjyz5Z4Fe3OLP');
          const fiscalDocSnap = await getDoc(fiscalDocRef);
          if (fiscalDocSnap.exists()) {
              this.fiscalInfo = fiscalDocSnap.data();
              this.applyFiscalInfoToCalendar();
          }
      },
      applyFiscalInfoToCalendar() {
          if (!this.fiscalInfo || !this.fiscalInfo.claimCutoff) {
              console.error('Fiscal info or claimCutoff is missing.');
              return;
          }
          const claimCutoffDayIndex = this.getDayIndex(this.fiscalInfo.claimCutoff);
          for (let month of this.months) {
              for (let week of month.weeks) {
                  for (let day of week) {
                      const dateStr = `${month.name} ${day.date}`;
                      day.isPayrollPayment = false;
                      day.isPayrollCutoff = false;
                      day.isClaimCutoff = false;
                      day.isMonthEndClosingWeekend = false;
                      day.isMonthEndClosingWeek = false;
                      if (this.fiscalInfo.payrollPayment === 'Last Day of the Month' && this.isLastDayOfMonth(day.date, month)) {
                          day.isPayrollPayment = true;
                      } else if (this.fiscalInfo.payrollPayment === 'Last Weekday of the Month' && this.isLastWeekdayOfMonth(day.date, month)) {
                          day.isPayrollPayment = true;
                      }
                      if (day.isPayrollPayment) {
                          continue;
                      }
                      if (day.date === this.fiscalInfo.payrollCutoff && !day.isPrevMonth && !day.isNextMonth) {
                          day.isPayrollCutoff = true;
                      }
                      if (day.isPayrollCutoff) {
                          continue;
                      }
                      const claimCutoffDates = this.getAllDaysOfSpecificDayInMonth(month.year, month.index, claimCutoffDayIndex);
                      if (claimCutoffDates.includes(day.date) && !day.isPrevMonth && !day.isNextMonth) {
                          day.isClaimCutoff = true;
                      }
                      if (day.isClaimCutoff) {
                          continue;
                      }
                      if (this.isWeekend({ year: month.year, month: month.index, date: day.date }) && 
                          this.isMonthEndClosingWeek(day.date, month, this.fiscalInfo.monthEndClosingWeek) && 
                          !day.isPrevMonth && !day.isNextMonth) {
                          day.isMonthEndClosingWeekend = true;
                      }
                      if (day.isMonthEndClosingWeekend) {
                          continue;
                      }
                      if (this.isMonthEndClosingWeek(day.date, month, this.fiscalInfo.monthEndClosingWeek) && 
                          !day.isPrevMonth && !day.isNextMonth) {
                          day.isMonthEndClosingWeek = true;
                      }
                  }
              }
          }
      },
      getDayIndex(dayName) {
          const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          return days.indexOf(dayName);
      },
      getAllDaysOfSpecificDayInMonth(year, month, dayIndex) {
          const dates = [];
          const firstDayOfMonth = new Date(year, month, 1).getDay();
          let firstOccurrence = 1 + ((dayIndex - firstDayOfMonth + 7) % 7);
          for (let date = firstOccurrence; date <= new Date(year, month + 1, 0).getDate(); date += 7) {
              dates.push(date);
          }
          return dates;
      },
      isLastDayOfMonth(day, month) {
          const lastDate = new Date(month.year, month.index + 1, 0).getDate();
          return day === lastDate;
      },
      isWeekend(date) {
          const day = new Date(date.year, date.month, date.date).getDay();
          return day === 0 || day === 6; // Sunday or Saturday
      },
      isLastWeekdayOfMonth(day, month) {
          let lastDate = new Date(month.year, month.index + 1, 0); // Last day of the month
          let lastWeekday = lastDate.getDate();
          // Adjust to the last weekday (Monday to Friday)
          while (lastDate.getDay() === 0 || lastDate.getDay() === 6) { // 0 = Sunday, 6 = Saturday
              lastDate.setDate(lastDate.getDate() - 1);
              lastWeekday = lastDate.getDate();
          }
          return day === lastWeekday;
      },
      isMonthEndClosingWeek(day, month, weekName) {
          if (!weekName) return false;
          const weekNumbers = {
              'week 1': [1, 2, 3, 4, 5, 6, 7],
              'week 2': [8, 9, 10, 11, 12, 13, 14],
              'week 3': [15, 16, 17, 18, 19, 20, 21],
              'week 4': [22, 23, 24, 25, 26, 27, 28],
          };
          return weekNumbers[weekName.toLowerCase()].includes(day);
      },
      isMonthEndClosingWeekend(day, month, weekName) {
          if (!this.isMonthEndClosingWeek(day, month, weekName)) return false;
          const date = new Date(month.year, month.index, day.date);
          return date.getDay() === 0 || date.getDay() === 6;
      },
      calculateNextPublicHoliday() {
          const today = new Date();
          let closestHoliday = null;
          let closestHolidayDate = null;
          this.holidays.forEach(holiday => {
              const [day, month] = holiday.date.split(' ');
              const holidayDate = new Date(today.getFullYear(), this.getMonthIndex(month), day);
              if (holidayDate >= today && (!closestHolidayDate || holidayDate < closestHolidayDate)) {
                  closestHoliday = holiday;
                  closestHolidayDate = holidayDate;
              }
          });
          this.nextPublicHoliday = closestHoliday;
      },
      getMonthIndex(monthName) {
          const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          return monthNames.indexOf(monthName);
      }
  },
  async mounted() {
      this.fetchCompanyName();
      this.fiscalYear = this.getFiscalYear();
      this.months = this.generateFiscalCalendar();
      await this.fetchFiscalInfo();
      await this.fetchHolidays();
      this.currentMonth = this.months.find(month => month.index === new Date().getMonth()); 
      this.fetchNewestAnnouncement();
      this.updateDateTime();
      this.interval = setInterval(this.updateDateTime, 1000);
  },
  unmounted() {
      clearInterval(this.interval);
  }
};
</script>


<style scoped>
.weeks {
  display: flex;
  justify-content: space-between;
  padding: 0;
  list-style: none;
  margin-bottom: 4px;
}
.weeks li {
  width: 14.28%;
}
.days {
  display: flex;
  flex-wrap: wrap;
}
.days div {
  width: 14.28%;
  text-align: center;
}
.table-auto {
  table-layout: auto;
}
.block {
display: flex;
flex-direction: column;
justify-content: space-between;
}
.bento-box {
position: relative;
overflow: hidden;
}
.bento-box img {
transition: transform 0.5s ease;
}
.bento-box:hover img {
transform: scale(1.1);
}
.group:hover .opacity-0 {
opacity: 1 !important;
}
.group:hover .group-hover\:blur-md {
filter: blur(3px);
}
.group:hover .group-hover\:scale-110 img {
transform: scale(1.3);
}
</style>