<template>
    <nav class= "px-4 pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
            <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
            <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">Organizational Chart</li>
        </ol>
    </nav>
    <PDFViewer documentId="1IG0vZYmR2evDXRTBqkZ" />
</template>

<script>
import PDFViewer from '@/components/PdfViewer.vue';

export default {
name: "OrganizationChart",
components: {
    PDFViewer
}
};
</script>

<style scoped>
</style>

