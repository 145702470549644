<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full">
      <div>
        <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
            <ol class="list-reset flex text-gray-700 dark:text-gray-300">
                <li>
                    <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
                </li>
                <li>
                    <span class="mx-2">></span>
                </li>
                <li>
                    <router-link to="/FinanceForms" class="text-company-color-1 hover:text-company-color-2">Finance Forms</router-link>
                </li>
                <li>
                    <span class="mx-2">></span>
                </li>
                    <li class="text-gray-500 dark:text-gray-400">Add Finance Form</li>
            </ol>
        </nav>
  
        <div class="flex flex-col items-center justify-center h-full p-4">
          <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full">
            <h2 class="font-bold text-2xl md:text-3xl mb-6">Add New Finance Form</h2>
            <form @submit.prevent="onSubmit" enctype="multipart/form-data" class="space-y-4">
              
              <!-- Form Title -->
              <div>
                <label for="title" class="block text-gray-700 dark:text-gray-300 mb-2">Form Title</label>
                <input
                  type="text"
                  v-model="title"
                  id="title"
                  required
                  placeholder="Enter form title"
                  class="mt-1 w-full rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2"
                />
              </div>
  
              <div class="mb-4">
                <label class="block text-gray-700 dark:text-gray-300 mb-2">Upload Image</label>
                <div
                  @click="triggerImageInput"
                  @dragover.prevent
                  @dragenter.prevent
                  @drop="onImageDrop"
                  class="border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <input type="file" ref="image" @change="onImageSelect" accept="image/*" class="hidden" />
                  <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                    <i class="fa-solid fa-image"></i>
                  </div>
                  <p v-if="selectedImage" class="mt-2 text-gray-600 dark:text-gray-300">{{ selectedImage.name }}</p>
                  <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop image here to upload</p>
                </div>
                <img v-if="imagePreview" :src="imagePreview" alt="Form Image Preview" class="mt-4 rounded-lg" />
              </div>
  
              <div>
                <label class="block text-gray-700 dark:text-gray-300 mb-2">Form Type</label>
                <div class="flex flex-col space-y-2">
                  <div>
                    <input type="radio" id="internalLink" value="internalLink" v-model="formType" required />
                    <label for="internalLink" class="ml-2">Internal Link</label>
                  </div>
                  <div>
                    <input type="radio" id="externalLink" value="externalLink" v-model="formType" required />
                    <label for="externalLink" class="ml-2">External Link</label>
                  </div>
                  <div>
                    <input type="radio" id="downloadable" value="downloadable" v-model="formType" required />
                    <label for="downloadable" class="ml-2">Downloadable</label>
                  </div>
                </div>
              </div>
  
              <div v-if="formType === 'internalLink' || formType === 'externalLink'" class="mt-4">
                <label for="link" class="block text-gray-700 dark:text-gray-300 mb-2">Form Link</label>
                <input
                  type="url"
                  v-model="link"
                  id="link"
                  required
                  placeholder="Enter the URL"
                  class="mt-1 w-full rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2"
                />
              </div>
  
              <div v-if="formType === 'downloadable'" class="mt-4">
                <label class="block text-gray-700 dark:text-gray-300 mb-2">Upload Document</label>
                <div
                  @click="triggerFileInput"
                  @dragover.prevent
                  @dragenter.prevent
                  @drop="onFileDrop"
                  class="border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <input type="file" ref="file" @change="onFileSelect" class="hidden" />
                  <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                    <i class="fa-solid fa-download"></i>
                  </div>
                  <p v-if="selectedFile" class="mt-2 text-gray-600 dark:text-gray-300">{{ selectedFile.name }}</p>
                  <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop document here to upload</p>
                </div>
              </div>
  
              <div>
                <button type="submit" class="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-300 focus:outline-none focus:shadow-outline">
                  Submit
                </button>
              </div>
            </form>
  
            <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
              <img :src="loadingGif" alt="Loading..." class="h-28" />
              <p class="text-white ml-3">Loading...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { storage, db } from "@/firebase/init";
import loadingGif from "@/assets/images/loading.gif";
import { useToast } from "vue-toastification";

export default {
name: "AddFinanceForm",
data() {
    return {
    title: "",
    selectedImage: null,
    imagePreview: null,
    formType: "",
    link: "",
    selectedFile: null,
    message: "",
    loading: false, 
    loadingGif
    };
},
methods: {
    triggerImageInput() {
      this.$refs.image.click();
    },
    onImageSelect() {
      const file = this.$refs.image.files[0];
      this.validateImage(file);
    },
    onImageDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.validateImage(file);
    },
    validateImage(file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      if (!allowedTypes.includes(file.type)) {
          this.message = "Only image files (JPEG, PNG, GIF, WebP) are allowed!";
          this.selectedImage = null;
          this.imagePreview = null;
      } else if (file.size > 1000000) {
          this.message = "Too large, max size allowed is 1000KB";
          this.selectedImage = null;
          this.imagePreview = null;
      } else {
          this.selectedImage = file;
          this.imagePreview = URL.createObjectURL(file);
          this.message = "";
      }
    },
    triggerFileInput() {
      this.$refs.file.click();
    },
    onFileSelect() {
      const file = this.$refs.file.files[0];
      this.validateFile(file);
    },
    onFileDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.validateFile(file);
    },
    validateFile(file) {
      const allowedTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];
      if (!allowedTypes.includes(file.type)) {
        this.message = "Only .doc and .docx files are allowed!";
        this.selectedFile = null;
      } else if (file.size > 1000000) {
        this.message = "Too large, max size allowed is 1000KB";
        this.selectedFile = null;
      } else {
        this.selectedFile = file;
        this.message = "";
      }
    },
    async onSubmit() {
      if (!this.title) {
        this.message = "Please provide a title.";
        return;
      }
      if (this.formType === "downloadable" && !this.selectedFile) {
        this.message = "Please select a valid file.";
        return;
      }
      if ((this.formType === "internalLink" || this.formType === "externalLink") && !this.link) {
        this.message = "Please provide a valid URL.";
        return;
      }

      const toast = useToast();
      this.loading = true;

      try {
        let imageUrl = "";
        if (this.selectedImage) {
          const imageName = `Finance_Forms_Image/${this.selectedImage.name}`;
          const imageRef = storageRef(storage, imageName);
          await uploadBytes(imageRef, this.selectedImage);
          imageUrl = await getDownloadURL(imageRef);
        }

        let linkUrl = this.link;
          if (this.formType === "downloadable" && this.selectedFile) {
          const fileName = `Finance Forms/${this.selectedFile.name}`;
          const fileRef = storageRef(storage, fileName);
          await uploadBytes(fileRef, this.selectedFile);
          linkUrl = await getDownloadURL(fileRef);
        }

        await addDoc(collection(db, "Finance_Forms"), {
          title: this.title,
          imageSrc: imageUrl,
          type: this.formType,
          link: linkUrl,
        });

        toast.success("Finance form added successfully!");
        this.resetForm();
      } catch (error) {
        console.error("Error adding finance form:", error);
        toast.error("Failed to add finance form. Please try again.");
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
    this.title = "";
    this.selectedImage = null;
    this.imagePreview = null;
    this.selectedFile = null;
    this.link = "";
    this.formType = "";
    this.message = "";
    }
}
};
</script>

<style scoped>
</style>
  