<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">Employee Contacts</li>
        <li v-if="isEditEmployeeContacts"><span class="mx-2">></span></li>
        <li v-if="isEditEmployeeContacts">
          <router-link to="/AddEmployeeContacts" class="text-red-500 hover:text-red-300">Add Employee Contacts</router-link>
        </li>
      </ol>
    </nav>
    
    <div class="w-full 2xl:w-3/4 mx-auto mt-8 p-2">
      <div class="flex justify-between items-center mb-4">
        <h2 class="font-bold text-2xl md:text-3xl">Employee Contacts</h2>
      </div>
      <div class="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 mb-4">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search for employees..."
          class="flex-1 p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
        />
        <select v-model="groupByDepartment" class="flex-1 sm:w-auto bg-white dark:bg-gray-800 p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
          <option value="">All Departments</option>
          <option v-for="dept in departments" :key="dept" :value="dept">{{ dept }}</option>
        </select>
        <select v-model="sortBy" class="flex-1 sm:w-auto bg-white dark:bg-gray-800 p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
          <option value="name">Sort by Name</option>
          <option value="employeeNumber">Sort by Employee Number</option>
        </select>
      </div>
      <div class="overflow-x-auto">
        <div class="min-w-full inline-block align-middle overflow-hidden bg-white dark:bg-gray-800 mb-4 shadow sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600 text-xs lg:text-lg">
            <thead class="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
                <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Department</th>
                <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Position</th>
              </tr>
            </thead>
            <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-600">
              <tr v-for="employee in filteredEmployees" :key="employee.id">
                <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.name }}</td>
                <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.email }}</td>
                <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.phone }}</td>
                <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.department }}</td>
                <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.position }}</td>
              </tr>
            </tbody>
          </table>
          <div class="ml-8 md:ml-0 flex justify-start md:justify-between p-4 md:items-center">
            <div class="flex flex-col md:flex-row w-fit md:w-full items-center justify-start md:justify-between">
              <div class="text-sm lg:text-md mb-4 lg:mb-0">
                Rows per page:
                <select v-model="rowsPerPage" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2">
                  <option v-for="option in [5, 10, 15, 20]" :key="option" :value="option">{{ option }}</option>
                </select>
              </div>
              <div class="text-sm lg:text-md">
                <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded mr-2">Prev</button>
                {{ paginationInfo }}
                <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded ml-2">Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '@/firebase/init';
import { getLocalStorageWithTimeout } from '@/utils/localStorageUtil';

export default {
  data() {
    return {
      employees: [],
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      groupByDepartment: '',
      sortBy: 'name',
      departments: [],
      isEditEmployeeContacts: false,
    };
  },
  computed: {
    allFilteredEmployees() {
      let filtered = this.employees.filter(employee => employee.name !== 'software department admin');

      if (this.searchQuery) {
        filtered = filtered.filter(employee =>
          Object.values(employee).some(value =>
            String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        );
      }

      if (this.groupByDepartment) {
        filtered = filtered.filter(employee =>
          employee.department === this.groupByDepartment
        );
      }

      if (this.sortBy === 'name') {
        filtered.sort((a, b) => a.name.localeCompare(b.name));
      } else if (this.sortBy === 'employeeNumber') {
        filtered.sort((a, b) => a.employeeNumber.localeCompare(b.employeeNumber));
      }

      return filtered;
    },
    filteredEmployees() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.allFilteredEmployees.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.allFilteredEmployees.length / this.rowsPerPage);
    },
    paginationInfo() {
      const start = (this.currentPage - 1) * this.rowsPerPage + 1;
      const end = Math.min(start + this.rowsPerPage - 1, this.allFilteredEmployees.length);
      return `${start} - ${end} of ${this.allFilteredEmployees.length}`;
    }
  },
  methods: {
    fetchData() {
      const q = query(collection(db, "employees"), orderBy("name", "asc"));
      onSnapshot(q, (querySnapshot) => {
        this.employees = [];
        const departmentsSet = new Set();
        querySnapshot.forEach((doc) => {
          const data = { id: doc.id, ...doc.data() };
          this.employees.push(data);
          departmentsSet.add(data.department);
        });
        this.departments = Array.from(departmentsSet);
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },
  watch: {
    rowsPerPage() {
      this.currentPage = 1;
    }
  },
  created() {
    this.fetchData();
    this.isEditEmployeeContacts = getLocalStorageWithTimeout('isEditEmployeeContacts', false);
  }
};
</script>

<style scoped>

</style>
