<template>
  <nav class="px-4 pt-2 mb-1 mx-4 text-xs lg:text-md h-6">
    <ol class="list-reset flex text-gray-700 dark:text-gray-300">
      <li>
        <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
      </li>
      <li>
        <span class="mx-2">></span>
      </li>
      <li class="text-gray-500 dark:text-gray-400">Company Structure</li>
    </ol>
  </nav>
  <PDFViewer documentId="1Dls63sGpDqvvwPynDwu" />
</template>

<script>
import PDFViewer from '@/components/PdfViewer.vue';

export default {
  name: "CompanyStructure",
  components: {
    PDFViewer
  }
};
</script>

<style scoped>
</style>
