<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
            <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li>
          <router-link to="/HRTemplates" class="text-company-color-1 hover:text-company-color-2">HR Templates</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">HR Templates Uploader</li>
      </ol>
    </nav>
    <div class="flex flex-col items-center justify-center h-full p-4">
      <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full">
        <p class="text-2xl lg:text-3xl font-semibold mb-10">
          Please upload the newest version of <span class="text-company-color-1">{{ docName }}</span>
        </p>
        <form @submit.prevent="onSubmit" enctype="multipart/form-data">
          <p class="text-sm text-gray-600 dark:text-gray-300 mb-4">
            <span class="text-red-600">Note:</span> The uploaded file should differ from the original file in formatting and styling only, not in the variable aspect for form generation. Contact the <span class="text-blue-400 font-semibold">IT department</span> for changes regarding variables.
          </p>
          <div class="mb-4">
            <label class="block text-gray-700 dark:text-gray-300 mb-2">Upload File</label>
            <div @click="triggerFileInput" @dragover.prevent @dragenter.prevent @drop="onDrop" class="border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700">
              <input type="file" ref="file" @change="onSelect" class="hidden" />
              <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                <i class="fa-solid fa-download"></i>
              </div>
              <p v-if="selectedFile" class="mt-2 text-gray-600 dark:text-gray-300">{{ selectedFile.name }}</p>
              <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop files here to upload</p>
            </div>
          </div>
          <div v-if="message" class="my-4">
            <h5 class="text-gray-700 dark:text-gray-300">{{ message }}</h5>
          </div>
          <div>
            <button type="submit" class="w-28 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-300 focus:outline-none focus:shadow-outline">
              Submit
            </button>
          </div>
        </form>
        <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
          <img :src="loadingGif" alt="Loading..." class="h-28" />
          <p class="text-white ml-3">Loading...</p>
        </div>
      </div>
      <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full mt-4">
        <p class="text-xl lg:text-2xl font-semibold mb-10">
          Click here if you wish to download the <span class="text-company-color-1">current version</span> of the template
        </p>
        <button @click="downloadCurrentVersion" class="w-28 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-400 focus:outline-none focus:shadow-outline">
          Download
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { storage, db } from "@/firebase/init";
import loadingGif from "@/assets/images/loading.gif";
import { useToast } from "vue-toastification";

export default {
  name: "FileUploader",
  props: {
    docName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedFile: null,
      message: "",
      loading: false,
      loadingGif
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.file.click();
    },
    onSelect() {
      const allowedTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ];
      const file = this.$refs.file.files[0];
      this.selectedFile = file;
      this.validateFile(file);
    },
    onDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.selectedFile = file;
      this.validateFile(file);
    },
    validateFile(file) {
      const allowedTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ];
      if (!allowedTypes.includes(file.type)) {
        this.message = "Only .doc, .docx, .xls, and .xlsx files are allowed!";
        this.selectedFile = null;
      } else if (file.size > 1000000) {
        this.message = "Too large, max size allowed is 1000KB";
        this.selectedFile = null;
      } else {
        this.message = "";
      }
    },
    async onSubmit() {
      if (!this.selectedFile) {
        this.message = "Please select a valid file";
        return;
      }

      const toast = useToast();
      const fileName = `HR Templates/${this.docName}`;
      const fileRef = storageRef(storage, fileName);

      this.loading = true;

      try {
        await deleteObject(fileRef).catch(error => {
          if (error.code !== 'storage/object-not-found') {
            throw error;
          }
        });

        await uploadBytes(fileRef, this.selectedFile);
        const fileURL = await getDownloadURL(fileRef);

        await setDoc(doc(db, 'templates', this.docName), { url: fileURL });

        toast.success("File uploaded successfully!");
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Upload failed!");
      } finally {
        this.loading = false;
      }
    },
    async downloadCurrentVersion() {
      const toast = useToast();
      try {
        const docRef = doc(db, 'templates', this.docName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          toast.info('Download started. Please wait...');
          const fileURL = docSnap.data().url;

          const link = document.createElement('a');
          link.href = fileURL;
          link.download = `${this.docName}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast.success("Downloaded Successfully!");
        } else {
          toast.error("No current version found!");
        }
      } catch (error) {
        console.error("Error downloading file:", error);
        toast.error("Download failed!");
      }
    }
  }
};
</script>

<style scoped>
</style>
